import DialogActionWall from 'pages/components/dialogActionWall';
import React from 'react';
import { colors } from 'styles/colors';

interface ITermsOfUse {
    openModal: boolean;
    setOpenModal: (event: boolean) => void;
}

const TermsOfUse = ({ openModal, setOpenModal }: ITermsOfUse) => {
    return (
        <DialogActionWall
            open={openModal}
            closeIconButton
            onClose={() => setOpenModal(false)}
            title={''}
            dialogTitleSxProps={{
                textAlign: 'center'
            }}
            dialogActionsSxProps={{
                justifyContent: 'center'
            }}
            dialogPaperSxProps={{
                maxWidth: 900
            }}
        >
            <div style={{textAlign:'justify'}}>
                    <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
                        <strong>Privacy Policy </strong>
                    </p>
                    <p>
                        4um values the privacy & security of everyone who is a part of our
                        community. We have instituted the following privacy & security
                        measures to create a privacy-centric and secure platform that is
                        compliant with relevant privacy laws.
                    </p>
                  <p style={{ textAlign: 'center', color: colors.text.primaryTheme }}>
                        <strong>Collection of your Information</strong>
                    </p>
                <p>
                    The specific types of information we collect include:
                </p>
                <p>
                    Personal Account and Profile Data: Name, username, age and date of
                    birth, proof of identification, password, email, phone number,
                    location, address, language settings, uploaded images, media,
                    registration data, login data, personal profession, and other
                    information provided by the user;
                </p>
                <p>
                    User Generated Content: Uploads, videos, quotes, comments, streams,
                    audio, hashtags, reposts, sent messages, received messages, read
                    messages, activity on the platform, clipboard data, user settings and
                    preferences, security data, data from platform usage, data from
                    surveys, promotions, challenges, contests, marketing, events, data
                    collected from cookies, and associate metadata;
                </p>
                <p>
                    Purchase Information: Transaction history, billing and shipping
                    addresses, and payment information;
                </p>
                <p>
                    Correspondence: Information from requests you send to our support
                    teams;
                </p>
                <p>
                    Contacts: Friends, followers, and phone contacts;
                </p>
                <p>
                    Device Specific Information: Mobile carrier, GPS, WiFi, IP Address,
                    time zone, and OS.
                </p>
                <div>
                  <p style={{ textAlign: 'center', color: colors.text.primaryTheme }}>
                        <strong>Use of your Information</strong>
                    </p>
                </div>
                <p>
                    4um utilizes user data in the following ways:
                </p>
                <p>
                    Customization: To customize specific Content, features, suggestions
                    and services;
                </p>
                <p>
                    Advertising/Marketing: To automate, personalize, and deliver targeted
                    advertisements and marketing materials;
                </p>
                <p>
                    Business Analytics: To provide analytics to our advertisers and
                    marketers to help them gauge advertising efficacy and user response;
                </p>
                <p>
                    Product Improvement: To personalize, develop, and improve products on
                    our platform including training algorithms and machine learning
                    models;
                </p>
                <p>
                    Communication: To contact, email, and provide notifications to our
                    users;
                </p>
                <p>
                    Research & Innovation: To better serve our communities, public
                    interests, and social concerns;
                </p>
                <p>
                    Safety & Security: To verify user accounts, identify user violations,
                    detect fraud, counteract dangerous conduct, and ensure the well-being
                    and safety of users on our platform;
                </p>
                <p>
                    Law Enforcement : To remain compliant with relevant laws and regulations;
                </p>
                <p>Job Applications & Recommendations;</p>
                <p>Promotional Materials; and</p>
                <p>Sales & Support.</p>
                <div>
                  <p style={{ textAlign: 'center', color: colors.text.primaryTheme }}>
                        <strong>Sharing of Information</strong>
                    </p>
                </div>
                <p>
                    4um shares user data with others in the following ways:
                </p>
                <p>
                    Publicly Available Information: Publicly available information and
                    Content visible on your profile;
                </p>
                <p>
                    Service Providers & Partners: With advertisers, business operations,
                    marketers, partners, service providers, and vendors;
                </p>
                <p>
                    Law Enforcement: With government, judicial, law enforcement, or
                    regulatory bodies when requisite to comply laws, legal requests,
                    promote safety, regulations, or to address security matters;
                </p>
                <p>
                    Under Your Consent: For reasons specified explicitly by your approval;
                </p>
                <p>
                    Upon Your Request: Sharing your contact or profile information with
                    third parties explicit upon your request;
                </p>
                <p>
                    Affiliates: With related group companies or affiliated platforms; and
                </p>
                <p>
                    Cross-Border Data Sharing: Internationally to operate our platform and
                    improve services.
                </p>
                <div>
                  <p style={{ textAlign: 'center', color: colors.text.primaryTheme }}>
                        <strong>Content Removal</strong>
                    </p>
                </div>
                <p>
                    Users who would like to remove their data from our platform may delete
                    their accounts in the settings tab. Following your request, we will
                    begin the deletion process which will take no longer than thirty (30)
                    days to remove your public information from our platform and no longer
                    than one hundred twenty (120) days to remove your information from our
                    servers (with limitations including (i) if deleting your information
                    would impair our ability to comply with legal requests, (ii) deleting
                    your information would violate our Terms of Use, (iii) where technical
                    reasons prevent us from doing so, (iv) or if your information or
                    Content is contained on another person’s account).
                </p>
                <p>
                    Parties may request to have the unauthorized sharing of their private
                    information removed from our platform by sending a privacy complaint
                    to  <u style={{ color: colors.text.primaryTheme, textDecoration: 'none' }}>admin@4um.com</u>. The Content in question must be uniquely
                    distinguishable and include your personal information (including
                    government ID, image, name, personal details, or voice). Reasons to be
                    deliberated in your request include newsworthiness, whether the
                    information is already in the public domain, or if there is a genuine
                    public interest in sharing your information on our platform. Privacy
                    complaints should identify the unauthorized Content on our platform
                    (e.g. a link to the private information), contact information for the
                    party submitting the privacy complaint (including name, address,
                    telephone number, and email address), and a statement that the party
                    submitting the privacy complaint has not authorized sharing of their
                    personal information. After a privacy complaint is filed, we will give
                    notice to the person who uploaded the Content that they have
                    seventy-two (72) hours to completely remove the Content from our
                    platform. If the Content is not removed, and we deem that the uploaded
                    content is a violation of our Terms of Use or Policies, we will remove
                    the Content within twenty-four (24) hours. Users guilty of repeated
                    violations of our Privacy Policy face account suspension or permanent
                    ban from our platform.
                </p>
                <div>
                  <p style={{ textAlign: 'center', color: colors.text.primaryTheme }}>
                        <strong>State Specific Policies (including the California Consumer Privacy Act
                            (CCPA) Compliance, Colorado Privacy Act (CPA), Connecticut Data
                            Privacy Act (CTDPA), Utah Consumer Privacy Act (UCPA), and Virginia
                            Consumer Data Protection Act (VCDPA))</strong>
                    </p>
                </div>
                <p>
                    4um is committed to complying with State Specific Policies including
                    the California Consumer Privacy Act (CCPA), Colorado Privacy Act
                    (CPA), Connecticut Data Privacy Act (CTDPA), Utah Consumer Privacy Act
                    (UCPA), and Virginia Consumer Data Protection Act (VCDPA)
                    (collectively “State Specific Policies”). To ensure compliance, 4um
                    provides the following rights to users, including:
                </p>
                <p  >
                    Right to Know/Access: Users can request that 4um disclose what
                    personal data it collects, uses, discloses, and sells. This includes
                    providing a comprehensive report detailing:
                </p>
                <p>
                    Categories of personal information collected;
                </p>
                <p>
                    Purposes for which the information is used;
                </p>
                <p>
                    Sources from which the information is collected;
                </p>
                <p>
                    Third parties with whom the information is shared; and
                </p>
                <p>
                    Right to Correct: Users can request that 4um correct inaccuracies in
                    the personal data that it collects from them.
                </p>
                <p>
                    Right to Delete: Users can request the deletion of personal data that
                    4um has collected from them. This includes all records held by 4um and
                    any third-party service providers. Upon request, 4um will review to
                    see if any exemptions apply that may prevent full deletion (including
                    compliance with legal obligations, violations of our Terms of Service
                    or Policies, or detecting security incidents). If any exemptions apply,
                    the user will be notified that all or part of their personal
                    information cannot be deleted. If no exemptions apply, the user’s
                    personal data will be promptly deleted, and the user will be notified.
                </p>
                <p>
                    Right to Opt-Out: Users have the right to opt out of the selling of
                    their personal data or of targeted advertising based on their personal
                    information. Upon request, the opt-out request will be promptly
                    processed, and the user will be notified. Users will be provided
                    instructions on how to re-opt-in should they choose to do so in the
                    future. 4um will honor user’s opt-out preferences across all data
                    processing activities.
                </p>
                <p>
                    Right to Data Portability: Users have the right to have their personal
                    data conveyed in a portable, commonly used, and machine-readable
                    format.
                </p>
                <p>
                    Non-Discrimination: 4um will not discriminate against users who
                    exercise their State-Specific privacy rights. This ensures that users
                    will not face any adverse consequences, such as being denied services
                    or charged different prices, for exercising their privacy rights.
                </p>
                <div>
                  <p style={{ textAlign: 'center', color: colors.text.primaryTheme }}>
                        <strong>Privacy Requests</strong>
                    </p>
                </div>
                <p>
                    To exercise the privacy rights guaranteed under State-Specific
                    Policies, 4um has established the following detailed procedures:
                </p>
                <p>
                    Submitting Requests: Users can submit requests through email or mail
                    to ensure accessibility and convenience. Users should include their
                    name, username, address, phone number, email, and request. 4um will
                    respond to verifiable requests within 45 days, as required by law.
                </p>
                <p>
                    Email: Users can send requests to  <u style={{ color: colors.text.primaryTheme, textDecoration: 'none' }}>admin@4um.com</u>.
                </p>
                <p>
                    Mail: Written requests can be sent to 4um’s Data Privacy Team:
                </p>
                <p>4um Data Privacy</p>
                <p>8 The Green, Suite A</p>
                <p>Dover, DE 19901</p>
                <p>
                    Verification: To protect user privacy, 4um will verify the identity of
                    the requester using the following methods:
                </p>
                <p>
                    Email Verification: Sending a verification email to the registered
                    email address of the user.
                </p>
                <p>
                    Phone Verification: Contacting the user via their registered phone
                    number.
                </p>
                <p>
                    Government-Issued ID: In some cases, a copy of a government-issued ID
                    may be requested to confirm identity. This step ensures that personal
                    information is only disclosed to the rightful owner and prevents
                    unauthorized access.
                </p>
                <p>
                    Response Time: 4um will follow a strict timeline to ensure compliance
                    and timely responses:
                </p>
                <p>
                    Acknowled gment: We will acknowledge receipt of the request within 10 business days, informing the user that their request is being processed.
                </p>
                <p>
                    Substantive Response: We will provide a substantive response within 45 calendar days. If additional time is needed due to the complexity or volume of requests, 4um will inform the requester of the reason for the delay and the extension period (up to an additional 45 days).
                </p>
                <p>
                    Record-Keeping and Compliance: 4um will maintain records of all privacy requests and responses for at least 24 months to ensure compliance and transparency. These records will include:
                </p>
                <p>
                    Request Details: Date of request, nature of the request, and user verification process.
                </p>
                <p>
                    Response Actions: Steps taken to fulfill the request, including any data provided or deleted, and communications with the user.
                </p>
                <p>
                    Audit Logs: Detailed logs of all actions taken to process the request, ensuring accountability and facilitating internal and external audits.
                </p>
                <div>
                  <p style={{ textAlign: 'center', color: colors.text.primaryTheme }}>
                        <strong>Privacy Policy Updates</strong>
                    </p>
                </div>
                <p>
                    To stay current with evolving privacy laws, 4um will regularly review and update its Privacy Policies and procedures, ensuring compliance with new legal requirements and best practices. This includes:
                </p>
                <p>
                    Regular Policy Updates: Reviewing and updating privacy policies at least annually or as required by changes in law.
                </p>
                <p>
                    Employee Training: Providing regular training sessions and refresher courses to keep employees updated on privacy laws and best practices.
                </p>
                <p>
                    Audit and Monitoring: Conducting regular audits and monitoring of compliance with related privacy laws.
                </p>
                <p>
                    User Education: Informing users about their privacy rights and how to exercise them through clear and accessible communications on the 4um platform.
                </p>
                <p>
                    Audit and Monitoring: 4um will conduct regular audits to ensure that all requests are handled in accordance with legal requirements and internal policies. This includes:
                </p>
                <p>
                    Compliance Checks: Regularly reviewing request processing procedures to ensure they meet legal standards.
                </p>
                <p>
                    Internal Audits: Conducting internal audits to assess the effectiveness and efficiency of the privacy request process.
                </p>
                <p>
                    External Audits: Engaging third-party experts to perform independent audits and provide recommendations for improvement.
                </p>
                <p>
                    Feedback Mechanism: Implementing a feedback mechanism for users to report via  <u style={{ color: colors.text.primaryTheme, textDecoration: 'none' }}>admin@4um.com</u> any issues or concerns with the request process, allowing 4um to continuously improve its procedures.
                </p>
                <div>
                  <p style={{ textAlign: 'center', color: colors.text.primaryTheme }}>
                        <strong> Information Security Policy and Incident Response Plan</strong>
                    </p>
                </div>
                <p>
                    Information Security Policy: 4um is committed to protecting user data through robust security measures, including:
                </p>
                <p>
                    Access Control: Limiting access to personal data to authorized personnel only. This involves implementing role-based access controls to ensure that only individuals with a legitimate need to access data can do so.
                </p>
                <p>
                    Data Encryption: Using encryption technologies to protect data in transit and at rest. This ensures that even if data is intercepted, it remains unreadable and secure.
                </p>
                <p>
                    Regular Audits: Conducting regular security audits and vulnerability assessments to identify and address potential threats. These audits help ensure that security measures are effective and up-to-date.
                </p>
                <p>
                    Employee Training: Providing ongoing security training for employees to ensure they understand and comply with security protocols. Training covers topics such as phishing, password management, and data protection best practices.
                </p>
                <p>
                    Incident Response Plan: In the event of a security incident or data breach, 4um has established a comprehensive Incident Response Plan, which includes:
                </p>
                <p>
                    Incident Identification: Monitoring systems to detect potential security breaches and incidents. This involves using advanced monitoring tools and techniques to identify unusual activity or potential threats.
                </p>
                <p>
                    Incident Classification: Categorizing incidents based on their severity and potential impact on users and the platform. This helps prioritize response efforts and allocate resources effectively.
                </p>
                <p>
                    Response Team: Assembling an Incident Response Team consisting of members from legal, technology, PR, and other relevant departments. This team is responsible for coordinating the response to any privacy incidents and ensuring all necessary actions are taken.
                </p>
                <p>
                    Investigation: Conducting a thorough investigation to determine the cause and extent of the incident. This involves analyzing logs, reviewing system configurations, and interviewing relevant personnel.
                </p>
                <p>
                    Containment: Implementing measures to contain and mitigate the impact of any privacy or security breaches. This may involve isolating affected systems, blocking malicious IP addresses, and applying patches or updates.
                </p>
                <p  >
                    Notification: Informing affected users and regulatory authorities, as required by law, of any privacy or security breaches. Notifications will include details about the incident, the data affected, and steps users can take to protect themselves.
                </p>
                <p>
                    Recovery: Restoring affected systems and data to normal operation. This includes verifying that all threats have been removed and implementing additional security measures to prevent future incidents.
                </p>
                <p>
                    Documentation: Keeping detailed records of the incident and response actions taken. Documentation helps with future investigations and can be used to improve response processes.
                </p>
                <p>
                    Review and Improvement: Analyzing the incident to identify lessons learned and improve future response strategies. This involves conducting a post-incident review to identify areas for improvement and updating the Incident Response Plan accordingly.
                </p>
                <p>
                    4um will conduct annual reviews to refine the Incident Response Plan and to ensure preparedness for current and potential security incidents.
                </p>
            </div>
        </DialogActionWall >
    );
};

export default TermsOfUse;
