import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Routers from './routes';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import 'assets/scss/global.scss';
import { ThemeProvider } from '@mui/material/styles';
import { customTheme } from 'assets/scss/Theme';
import { PersistGate } from 'redux-persist/integration/react';
const App = () => {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={customTheme}>
            <BrowserRouter>
              <Routers />
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
