import { Done, DoneAll } from '@mui/icons-material';
import { AvatarGroup, Badge, Box, Chip, Divider, IconButton, InputAdornment, ListItemIcon, Menu, MenuItem, Modal, Paper, SelectChangeEvent, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { colors } from 'styles/colors';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { AddEmojiReaction, BlockIcon, DeleteEmojiIcon, ThumbsUpIcon, GrinningSquintingFaceIcon, GroupUserAdd, MaximizeChat, MinimizeChat, OpenMouthFaceIcon, PipeIcon, RedHeartIcon, ReportFriendIcon, SendMsgIcon, UnfriendIcon, StartConversationIcon, BlockAndReportIcon, MuteChatIcon } from 'assets/icon';
import AvatarImg from 'pages/components/avatarImg';
import { getUrl } from 'utilities/userImageURL';
import { CheckGreenCircle, CloseRoundIcon, DeleteIcon, MoreDotIcon } from 'assets';
import EmojiPicker from 'emoji-picker-react';
import { useNavigate } from 'react-router-dom';
import { axiosPostBlockUnblock, deleteConversation, deleteMessage, getAllMessages, reportConversation, sendMessage, updateDeliveredStatus, updateMessageReaction, updateReadStatus } from 'services/api';
import { useAppSelector } from 'store/hook';
import { RootState } from 'store';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import AdminIconButton from 'pages/components/iconButton';
import ConfirmationModal from 'pages/components/confirmationModal';
import DialogActionWall from 'pages/components/dialogActionWall';
import SelectDropdown from 'pages/components/selectDropdown';
import { ApiPaths } from 'models/apiPaths';

export interface IChatPopupProps {
    loggedUser: any;
    conversationId: string;
    friends: any[];
    onClose: (conversationId: string) => void;
    position: number;
}

export interface IMessage {
    id: string;
    senderId: string;
    participantIds: string[];
    content: string;
    reactions: Reaction[];
    sent: boolean;
    delivered: string[];
    read: string[];
    deletedBy: string[];
    createdAt: Date;
    modifiedAt: Date;
}

export interface IMessageRequest {
    senderId: string;
    participantIds: string[];
    content: string;
    sent: boolean;
    delivered: string[];
    read: string[];
    createdAt: Date;
}

interface Reaction {
    id: string;
    reaction: string;
    userId: string;
    createdAt: Date;
    modifiedAt?: Date;
}

export interface ISendMessageRequest {
    conversationId: string | null;
    message: IMessageRequest;
}

enum EmojiReaction {
    ThumbsUp = '👍',
    RedHeart = '❤️',
    GrinningSquintingFace = '😆',
    OpenMouthFace = '😮',
}

type ReactionIcon = {
    emoji: string;
    icon: any;
};

const reactionIcons: Record<EmojiReaction, ReactionIcon> = {
    [EmojiReaction.ThumbsUp]: { emoji: EmojiReaction.ThumbsUp, icon: <ThumbsUpIcon /> },
    [EmojiReaction.GrinningSquintingFace]: { emoji: EmojiReaction.GrinningSquintingFace, icon: <GrinningSquintingFaceIcon /> },
    [EmojiReaction.OpenMouthFace]: { emoji: EmojiReaction.OpenMouthFace, icon: <OpenMouthFaceIcon /> },
    [EmojiReaction.RedHeart]: { emoji: EmojiReaction.RedHeart, icon: <RedHeartIcon /> },
};

interface IOpenDialog {
    id?: string;
    open?: boolean;
    message?: string;
    label?: string;
    title?: string;
    userId?: string;
    conversationId?: string;
    username?: string;
    loaderBtn?: boolean;
    confirmTxt?: boolean;
    successModal?: boolean;
    successHandler?: () => void;
}

const ChatPopup: React.FC<IChatPopupProps> = ({ loggedUser, conversationId, friends, onClose, position }) => {
    const [minimized, setMinimized] = useState(false);
    const [friendsData, setFriendData] = useState<any[]>(friends);
    const [conversationData, setConversationData] = useState<any>();
    const [menuOpen, setMenuOpen] = useState(false);
    const [messages, setMessages] = useState<any[]>([]);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [message, setMessage] = useState("");
    const [showEmojiPickerFor, setShowEmojiPickerFor] = useState<string>();
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const navigate = useNavigate();
    const [groupedMessages, setGroupedMessages] = useState<Record<string, IMessage[]>>({});
    const MAX_CHAR_LIMIT = 1900;
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };
    const reason = [
        'Hate Speech',
        'Violence',
        'Inappropriate for public',
        'Nudity',
        'Other'
    ];
    const signalr = useAppSelector((state: RootState) => state.signalr);
    const scrollableBodyRef = useRef<HTMLDivElement | null>(null);
    const [openElem, setOpenElem] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [confirmModal, setConfirmModal] = React.useState<IOpenDialog>();
    const [loader, setLoader] = useState<string>('');
    const [selectedReaction, setSelectedReaction] = useState<any[]>([]);
    const [reportDescription, setReportDescription] = useState<string>('');
    const [selectedReason, setSelectedReason] = useState<string>('');
    const [reportModal, setReportModal] = useState<IOpenDialog>({
        open: false,
        id: '',
        loaderBtn: false,
        successModal: false
    });
    const [openDialog, setOpenDialog] = useState<IOpenDialog>({
        open: false,
        id: '',
        userId: '',
        loaderBtn: false,
        successModal: false
    });
    const [blockConfirmModal, setBlockConfirmModal] = useState<IOpenDialog>();
    const [showDetailedEmojiWindow, setShowDetailedEmojiWindow] = useState(false);
    const [reactionsToShow, setReactionsToShow] = useState<any[]>([]);
    const [isSending, setIsSending] = useState(false);
    const [openEmoji, setOpenEmoji] = useState(false);
    const emojiRef = useRef<HTMLDivElement | null>(null);
    const [selectedMessage, setSelectedMessage] = useState<IMessage>();

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedReason(event.target.value as string);
    };

    const handleClick = (elem: any) => (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };

    const getAvatar = (senderId: string) => {
        let sender = friendsData.find(friend => friend.userId === senderId);

        if (!sender && loggedUser && loggedUser.id === senderId) {
            sender = {
                "userId": loggedUser.id,
                "username": loggedUser.username,
                "firstName": null,
                "lastName": null,
                "imageId": loggedUser.userImage.id,
                "onlineStatus": false
            }
        }

        return <AvatarImg
            id='userImg'
            enableLetterImg={!sender.imageId}
            alt={sender.username}
            src={getUrl(sender.imageId)}
            name={sender.username}
            width={20}
            height={20}
            style={{
                objectFit: 'cover',
                borderRadius: '50%',
                border: `2px solid ${colors.border.primary}`
            }}
        />
    }

    const getReactions = (reactions: any[], sliceNeeded: boolean) => {
        const reactionMap: Record<string, { count: number; recentTimestamp: string; reaction: string; users: string[] }> = reactions.reduce(
            (acc, reaction) => {
                const key = reaction.reaction;
                const timestamp = reaction.modifiedAt || reaction.createdAt;
                const userId = reaction.userId;

                if (!acc[key]) {
                    acc[key] = { count: 0, recentTimestamp: timestamp, reaction: key, users: [] };
                }

                acc[key].count += 1;
                acc[key].users.push(userId);

                acc[key].recentTimestamp =
                    new Date(timestamp) > new Date(acc[key].recentTimestamp) ? timestamp : acc[key].recentTimestamp;

                return acc;
            },
            {} as Record<string, { count: number; recentTimestamp: string; reaction: string; users: string[] }>
        );

        const topReactions = Object.values(reactionMap)
            .sort((a, b) => new Date(b.recentTimestamp).getTime() - new Date(a.recentTimestamp).getTime());

        return sliceNeeded ? topReactions.slice(0, 3) : topReactions;
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (emojiRef.current && !emojiRef.current.contains(event.target as Node)) {
                setShowEmojiPicker(false);
                setOpenEmoji(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [emojiRef]);

    useEffect(() => {
        if (showDetailedEmojiWindow) {
            setSelectedReaction(getReactions(reactionsToShow, false));
        }
    }, [showDetailedEmojiWindow]);

    useEffect(() => {
        setFriendData((prevData) => {
            return prevData.map((friend) => {
                if (friend.userId === signalr.userStatus.userid) {
                    return {
                        ...friend,
                        onlineStatus: signalr.userStatus.isonline
                    };
                }
                return friend;
            });
        });
    }, [signalr.userStatus]);

    const getDisplayName = (friendsData: any[]) => {
        if (friendsData.length === 1) return friendsData[0].username;
        if (friendsData.length === 2) return `${friendsData[0].username}, ${friendsData[1].username}`;
        if (friendsData.length > 2) {
            return `${friendsData[0].username}, ${friendsData[1].username}, +${friendsData.length - 2}`;
        }
        return '';
    };

    const renderAvatar = (friend: any, index: number) => (
        <AvatarImg
            key={index}
            id="userImg"
            enableLetterImg={!friend.imageId}
            alt={friend.username}
            src={getUrl(friend.imageId)}
            name={friend.username}
            width={30}
            height={30}
            style={{
                objectFit: 'cover',
                borderRadius: '50%',
                border: `2px solid ${colors.border.primary}`,
                marginLeft: index > 0 ? -25 : 0,
            }}
        />
    );


    const getActiveStatus = (friendsData: any[]) => {
        if (friendsData.length === 1) return friendsData[0].onlineStatus ? 'Active Now' : 'Offline';
        else return `${friendsData.length} members`;
    };

    const getAvatars = (friends: any[]) => {
        if (friends.length === 1) {
            return friends.slice(0, 4).map((friend, index) => (
                <Badge
                    key={index}
                    sx={{
                        '& .MuiBadge-dot': {
                            borderRadius: '50%',
                            borderColor: 'white',
                            width: friend.onlineStatus ? '15px' : 0,
                            height: friend.onlineStatus ? '15px' : 0,
                            border: friend.onlineStatus ? `2px solid ${colors.border.primary}` : '',
                            backgroundColor: '#4caf50',
                        },
                    }}
                    variant="dot"
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {renderAvatar(friend, index)}
                </Badge>
            ));
        } else {
            return (
                <AvatarGroup
                    max={2}
                    spacing={25}
                    sx={{
                        '& .MuiAvatarGroup-avatar': {
                            width: 30,
                            height: 30,
                            fontSize: 16,
                            backgroundColor: colors.text.primaryTheme,
                        },
                    }}
                >
                    {friends.map((friend, index) => renderAvatar(friend, index))}
                </AvatarGroup>
            );
        }
    };

    const getConversation = () => {
        getAllMessages(conversationId)
            .then((data) => {
                const filteredMessages = data.conversation.messages.filter((message: any) => {
                    return !message.deletedBy.includes(loggedUser.id);
                });
                setMessages(filteredMessages);
                setConversationData(data);
            }
            ).catch((err) => {
                console.error("Error fetching conversations:", err);
            })
            .finally(() => {
                updateReadStatus(conversationId);
            });
    };

    const handleMessageUpdate = (input: string, isEmoji = false) => {
        let newMessage = message;
        let cursorPosition = 0;
        const textField = document.getElementById('messageTextField') as HTMLTextAreaElement;

        if (isEmoji) {            
            cursorPosition = textField.selectionStart;
            newMessage = message.slice(0, cursorPosition) + input + message.slice(cursorPosition);
            
            if (newMessage.length <= MAX_CHAR_LIMIT) {
                setMessage(newMessage);
                setTimeout(() => {
                    textField.focus();
                    textField.setSelectionRange(cursorPosition + input.length, cursorPosition + input.length);
                }, 0);
            }
        } else {
            newMessage = input;
            if (newMessage.length <= MAX_CHAR_LIMIT) {
                setMessage(newMessage);
            }
        }
    };

    const handleSendMessage = async () => {
        if (isSending) return;

        setIsSending(true);

        const inputMessage: IMessageRequest = {
            content: message,
            delivered: [],
            read: [],
            sent: true,
            participantIds: friendsData.map(friend => friend.userId),
            senderId: loggedUser.id,
            createdAt: new Date()
        }
        const messageRequest: ISendMessageRequest = {
            conversationId: conversationId,
            message: inputMessage
        }
        try {
            const res = await sendMessage(messageRequest);
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, res.message];
                setGroupedMessages(groupMessagesByDate(updatedMessages));
                return updatedMessages;
            });

            setMessage("");
        } finally {
            setIsSending(false);
        }
    }

    const handleAddReaction = async (messageId: string, emoji: string) => {

        const payload = {
            conversationId: conversationId,
            messageId: messageId,
            reaction: {
                reaction: emoji,
                userid: loggedUser.id
            },
        };

        var res = await updateMessageReaction(payload);

        setMessages((prevMessages) =>
            prevMessages.map((message) =>
                message.id === messageId ? res : message
            )
        );
    };

    const accountDelete = async (conversationId: any) => {
        setConfirmModal((pv) => ({
            ...pv,
            open: true,
            conversationId: conversationId,
            loaderBtn: true
        }));
        try {
            await handleDeleteConversation(conversationId);
        } catch (err) {
        } finally {
            setConfirmModal((pv) => ({
                ...pv,
                open: true,
                loaderBtn: false,
                conversationId: conversationId,
                message: `The conversation has been deleted successfully.`,
                confirmTxt: false
            }));
        }
    };

    const handleDeleteConversation = async (conversationId: string) => {
        await deleteConversation(conversationId).then(() => onClose(conversationId));
    }

    const handleConversationDelete = (conversationId: string) => {
        setConfirmModal((pv) => ({
            ...pv,
            open: true,
            conversationId: conversationId,
            message: `Are you sure you want to delete the conversation? \n This action cannot be undone.`,
            confirmTxt: true
        }));
    };

    const handleDeleteMessage = async (messageId: string) => {
        await deleteMessage(conversationId, messageId);

        setMessages((prevMessages) =>
            prevMessages.filter((message) => message.id !== messageId)
        );
    }

    const groupMessagesByDate = (messages: IMessage[]) => {
        return messages.reduce((groups, message) => {
            const date = new Date(message.createdAt).toDateString();
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(message);
            return groups;
        }, {} as Record<string, IMessage[]>);
    };

    const getSenderFullName = (senderId: string) => {
        let sender = friends.find(friend => friend.userId === senderId);

        if (!sender && loggedUser && loggedUser.id === senderId) {
            sender = loggedUser;
        }

        if (sender) {
            return (
                <TypographyWithI18N
                    i18nKey={sender.username}
                    fontSize={12}
                    sx={{
                        marginLeft: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                />
            );
        }
        return '';
    }

    const getSenderName = (senderId: string) => {
        let sender = friends.find(friend => friend.userId === senderId);

        if (sender) {
            return (
                <TypographyWithI18N
                    i18nKey={sender.username.split(' ')[0]}
                    fontSize={12}
                    sx={{
                        marginLeft: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                />
            );
        }
        return '';
    }

    const getRecipientName = (senderId: string) => {
        let sender = friends.find(friend => friend.userId === senderId);

        if (sender) {
            return sender.username;
        }
        return '';
    }

    const saveReportHandler = async () => {
        setReportModal((pv) => ({
            ...pv,
            loaderBtn: true
        }));
        setLoader(`account-menu_${reportModal.id}`);
        const payload = {
            conversationId: reportModal.id,
            reason: selectedReason,
            description: reportDescription
        };
        try {
            const response = await reportConversation(payload);
            if (!response.error) {
                setReportModal((pv) => ({
                    ...pv,
                    loaderBtn: false,
                    successModal: true
                }));
            }
        } catch (error: unknown) {
        } finally {
            setLoader('');
            getConversation();
        }
    };

    const blockUnblockHandler = async (blockedUserId: any) => {
        console.log(blockConfirmModal);
        const payload = {
            blockedUserId: blockedUserId
        };
        setBlockConfirmModal((pv) => ({
            ...pv,
            open: true,
            loaderBtn: true
        }));

        try {
            const apiPath = conversationData.isBlocked
                ? ApiPaths.USER_UN_BLOCK
                : ApiPaths.USER_BLOCK;
            await axiosPostBlockUnblock(apiPath, payload);
        } catch (error: unknown) {
            console.log(error);
        } finally {
            setBlockConfirmModal((pv) => ({
                ...pv,
                open: true,
                loaderBtn: false,
                message: `You have successfully ${conversationData.isBlocked ? 'unblocked' : 'blocked'} ${getRecipientName(blockedUserId)}.`,
                confirmTxt: false
            }));
            getConversation();
        }
    };

    const formatDate = (dateString: string): string => {
        const messageDate = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const isToday = messageDate.toDateString() === today.toDateString();
        const isYesterday = messageDate.toDateString() === yesterday.toDateString();

        if (isToday) return "Today";
        if (isYesterday) return "Yesterday";

        const daysDifference = Math.floor((today.getTime() - messageDate.getTime()) / (1000 * 60 * 60 * 24));
        if (daysDifference < 7) {
            return new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(messageDate);
        }

        if (messageDate.getFullYear() === today.getFullYear()) {
            return new Intl.DateTimeFormat('en-US', { weekday: 'long', month: 'long', day: 'numeric' }).format(messageDate);
        }

        return new Intl.DateTimeFormat('en-US', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' }).format(messageDate);
    };

    const handleIncomingMessage = (newMessage: any) => {
        const newConversationId = newMessage.conversationId;
        if (newConversationId === conversationId) {
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, newMessage.message];
                return updatedMessages;
            });
            updateDeliveredStatus().then().finally(() => {
                updateReadStatus(conversationId);
            });
        }
    }

    const handleUpdatedMessage = (newMessage: any) => {
        const newConversationId = newMessage.conversationId;
        if (newConversationId === conversationId) {
            setMessages((prevMessages) => {
                const updatedMessages = prevMessages.map((msg) =>
                    msg.id === newMessage.message.id ? newMessage.message : msg
                );
                return updatedMessages;
            });
        }
    }

    useEffect(() => {
        if (messages.length > 0) {
            handleIncomingMessage(signalr.messages);
        }

    }, [signalr.messages]);

    useEffect(() => {
        if (messages.length > 0) {
            handleUpdatedMessage(signalr.deliveredMessages);
        }

    }, [signalr.deliveredMessages]);

    useEffect(() => {
        setGroupedMessages(groupMessagesByDate(messages));
    }, [messages]);

    useEffect(() => {
        conversationId.length > 0 && getConversation();
    }, []);

    useEffect(() => {
        const scrollToBottom = () => {
            if (scrollableBodyRef.current) {
                scrollableBodyRef.current.scrollTop = scrollableBodyRef.current.scrollHeight;
            }
        };

        scrollToBottom();
    }, [groupedMessages]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                right: -position * 410,
                width: 400,
                height: minimized ? 'auto' : 500,
                boxShadow: 3,
                borderRadius: '8px 8px 0 0',
                display: 'flex',
                flexDirection: 'column',
                zIndex: 1300 + position
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 1, color: colors.text.primaryBlack }}>
                <Box sx={{ display: 'flex', marginLeft: 1 }} onClick={() => {
                    if (friends.length === 1 && friends[0]?.userId) {
                        navigate(`/profile/${friends[0].userId}`);
                    }
                }}>
                    {getAvatars(friendsData)}
                    <Box sx={{ marginLeft: 2, flexGrow: 1, cursor: 'pointer' }}>
                        <TypographyWithI18N
                            className='bodyTextSmallBold'
                            i18nKey={getDisplayName(friendsData)}
                            sx={{
                                color: colors.text.primaryBlack,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: 100,
                                textOverflow: 'ellipsis',
                            }} />
                        <TypographyWithI18N
                            i18nKey={getActiveStatus(friendsData)}
                            onMouseEnter={friends.length > 1 ? (event) => handleClick('members')(event) : undefined}
                            fontSize={12}
                            sx={{
                                color: colors.text.approxSuvaGrey,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: 100,
                                textOverflow: 'ellipsis',
                            }} />
                        <Menu
                            anchorEl={anchorEl}
                            open={openElem === 'members'}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            PaperProps={{
                                style: {
                                    width: 200
                                }
                            }}
                            sx={{
                                '& .MuiMenuItem-root': {
                                    minHeight: 30
                                }
                            }}
                        >
                            {
                                friends.map((friend, index) => {
                                    return <MenuItem key={index}
                                        onClick={() => navigate(`/profile/${friend.userId}`)}
                                    >
                                        <AvatarImg
                                            id="userImg"
                                            enableLetterImg={!friend?.imageId}
                                            alt={friend.username}
                                            src={getUrl(friend?.imageId)}
                                            name={friend?.username}
                                            nameStyle={{
                                                fontSize: 10,
                                            }}
                                            width={20}
                                            height={20}
                                            style={{
                                                objectFit: 'cover',
                                                borderRadius: '50%',
                                                marginRight: 10
                                            }}
                                        />
                                        <TypographyWithI18N
                                            className='bodyTextSmall ellipsisText'
                                            i18nKey={friend.username}
                                            color={colors.text.primaryBlack}
                                            maxWidth={200}
                                        />
                                    </MenuItem>
                                })
                            }
                        </Menu>
                    </Box>
                </Box>
                <Box>
                    <AdminIconButton
                        id='settings'
                        aria-label='more'
                        icon={<MoreDotIcon
                            style={{ width: 20, height: 20 }} />}
                        onClick={handleClick('submenu')}
                    />
                    <Menu
                        id={`account-menu`}
                        anchorEl={anchorEl}
                        open={openElem === 'submenu'}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        PaperProps={{
                            style: {
                                width: 150
                            }
                        }}
                        sx={{
                            '& .MuiMenuItem-root': {
                                minHeight: 20
                            }
                        }}
                    >
                        {friends.length === 1 && [
                            <MenuItem
                                key="block"
                                onClick={() => {
                                    handleClose();
                                    let receiver = friends.find(friend => friend.userId !== loggedUser.userId);
                                    setBlockConfirmModal((pv) => ({
                                        ...pv,
                                        open: true,
                                        id: conversationId,
                                        username: receiver.username,
                                        message: `Are you sure you want to ${conversationData.isBlocked ? 'unblock' : 'block'} ${receiver.username}?`,
                                        label: conversationData.isBlocked ? 'Unblock' : 'Block',
                                        title: conversationData.isBlocked ? 'Unblock User' : 'Block User',
                                        confirmTxt: true,
                                        successHandler: () => {
                                            blockUnblockHandler(receiver.userId);
                                        }
                                    }));
                                }}
                                sx={{ height: 25 }}
                            >
                                <ListItemIcon>
                                    <BlockIcon
                                        style={{ width: 20, height: 18 }}
                                    />
                                </ListItemIcon>
                                <TypographyWithI18N
                                    className='bodyText'
                                    i18nKey={conversationData?.isBlocked ? 'Unblock' : `Block`}
                                    style={{ fontSize: 14 }}
                                    color={colors.text.primaryBlack}
                                />
                            </MenuItem>,
                            <Divider />,
                            <MenuItem
                                key="report"
                                onClick={() => {
                                    handleClose();
                                    setReportModal((pv) => ({
                                        ...pv,
                                        open: true,
                                        id: conversationId,
                                    }));
                                }}
                                disabled={conversationData?.isReported}
                                sx={{ height: 25 }}
                            >
                                <ListItemIcon>
                                    <BlockAndReportIcon
                                        style={{ width: 20, height: 18 }}
                                    />
                                </ListItemIcon>
                                <TypographyWithI18N
                                    className='bodyText'
                                    i18nKey={`Report`}
                                    style={{ fontSize: 14 }}
                                    color={colors.text.primaryBlack}
                                />
                            </MenuItem>,
                            <Divider />
                        ]}

                        <MenuItem
                            onClick={() => {
                                handleConversationDelete(conversationId);
                            }}
                            sx={{ height: 25 }}
                        >
                            <ListItemIcon>
                                <DeleteIcon
                                    style={{ width: 20, height: 18 }} />
                            </ListItemIcon>
                            <TypographyWithI18N
                                className='bodyText'
                                i18nKey={`Delete Chat`}
                                style={{ fontSize: 14 }}
                                color={colors.text.primaryBlack}
                            />
                        </MenuItem>
                    </Menu>
                    <AdminIconButton
                        id='minimize'
                        aria-label='minormax'
                        icon={minimized ?
                            <MaximizeChat style={{ width: 20, height: 20 }} /> :
                            <MinimizeChat style={{ width: 20, height: 20 }} />}
                        onClick={() => setMinimized(!minimized)}
                    />
                    <AdminIconButton
                        id='close'
                        aria-label='close'
                        icon={<CloseRoundIcon
                            style={{ width: 20, height: 20 }} />}
                        onClick={() => onClose(conversationId)}
                    />
                </Box>
            </Box>
            {menuOpen && (
                <Box
                    ref={menuRef}
                    sx={{
                        position: 'absolute',
                        left: -210,
                        width: 200,
                        bgcolor: 'background.paper',
                        boxShadow: 3,
                        borderRadius: 2,
                    }}
                >
                    <MenuItem onClick={() => { alert('Block'); handleMenuToggle(); }}><IconButton><BlockIcon /></IconButton> Block</MenuItem>
                    <MenuItem onClick={() => { alert('Report'); handleMenuToggle(); }}><IconButton><ReportFriendIcon /></IconButton> Block and Report</MenuItem>
                    <MenuItem onClick={() => { alert('Report'); handleMenuToggle(); }}><IconButton><UnfriendIcon /></IconButton> Report</MenuItem>
                    <MenuItem onClick={() => { alert('Delete Chat'); handleMenuToggle(); }}><IconButton><UnfriendIcon /></IconButton> Delete Chat</MenuItem>
                </Box>
            )}
            {!minimized && (
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                    padding: [2, 2, 2, 2]

                }}
                    alignItems={Object.keys(groupedMessages).length === 0 ? 'center' : ''}
                    justifyContent={Object.keys(groupedMessages).length === 0 ? 'center' : ''}
                    ref={scrollableBodyRef}>
                    {Object.keys(groupedMessages).length === 0 ? (
                        <>
                            <StartConversationIcon />
                            <TypographyWithI18N
                                className='smallHeader'
                                textAlign={'center'}
                                i18nKey={'Start Conversation !'}
                            />
                        </>
                    ) : (
                        <>
                            {Object.entries(groupedMessages).map(([date, messages]) => (
                                <Box key={date}>
                                    <Typography variant="caption" sx={{ textAlign: 'center', display: 'block', marginBottom: 1 }}>
                                        {formatDate(date)}
                                    </Typography>
                                    {messages.map((message, index) => {
                                        const isLastInGroup = index === messages.length - 1 || message.senderId !== messages[index + 1]?.senderId;
                                        const hasReactions = message.reactions != null && message.reactions.length > 0;
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    justifyContent: message.senderId === loggedUser.id ? 'flex-end' : 'flex-start',
                                                    marginBottom: hasReactions ? 4 : 1,
                                                    position: 'relative'
                                                }}
                                                onMouseLeave={() => setShowEmojiPickerFor("")}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: message.senderId === loggedUser.id ? 'flex-end' : 'flex-start',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            padding: 1,
                                                            backgroundColor: message.senderId === loggedUser.id ? '#208d92' : '#f5f5f5',
                                                            color: message.senderId === loggedUser.id ? 'white' : 'black',
                                                            borderRadius: message.senderId === loggedUser.id ? '30px 30px 0 30px' : '30px 30px 30px 0',
                                                            minWidth: '50%',
                                                            position: 'relative',
                                                        }}
                                                        onMouseEnter={() => setShowEmojiPickerFor(message.id)}
                                                    >
                                                        <Typography
                                                            fontSize={14}
                                                            sx={{
                                                                margin: '2px 5px 2px 5px',
                                                                wordBreak: 'break-word'
                                                            }}
                                                            variant="body2"
                                                        >
                                                            {message.content}
                                                        </Typography>
                                                        {message.reactions != null && message.reactions.length > 0 && (
                                                            <Chip
                                                                onClick={(event) => { event.stopPropagation(); setShowDetailedEmojiWindow(true); setReactionsToShow(message.reactions) }}
                                                                sx={{
                                                                    position: 'absolute',
                                                                    left: message.senderId !== loggedUser.id ? 'auto' : 0,
                                                                    right: message.senderId !== loggedUser.id ? 0 : 'auto',
                                                                    backgroundColor: colors.background.primaryBackground,
                                                                    border: `2px solid ${colors.background.primaryTab}`,
                                                                    height: 25,
                                                                    fontSize: 14,
                                                                    '&:hover': {
                                                                        backgroundColor: colors.background.primaryBackground,
                                                                    },
                                                                }}
                                                                label={getReactions(message.reactions, true).map((reaction) => (reaction.reaction))}
                                                            />
                                                        )}
                                                    </Box>
                                                    {isLastInGroup && (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: message.senderId === loggedUser.id ? 'flex-end' : 'flex-start',
                                                                marginBottom: 1,
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            {message.senderId === loggedUser.id ? (
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >{message.participantIds.every(id => message.read.includes(id)) ? (
                                                                        <DoneAll color="primary"
                                                                            style={{ width: 20, height: 20 }} />
                                                                    ) : message.participantIds.every(id => message.delivered.includes(id)) ? (
                                                                        <DoneAll style={{ width: 20, height: 20 }} />
                                                                    ) : (
                                                                        <Done style={{ width: 20, height: 20 }} />
                                                                    )}
                                                                    </Box>
                                                                    <TypographyWithI18N
                                                                        i18nKey={new Date(message.createdAt).toLocaleTimeString('en-US', {
                                                                            hour: 'numeric',
                                                                            minute: 'numeric',
                                                                            hour12: true,
                                                                        })}
                                                                        fontSize={10}
                                                                        sx={{
                                                                            color: colors.text.approxSuvaGrey,
                                                                            margin: '5px 15px 5px 10px',
                                                                        }}
                                                                    />
                                                                    <Box>
                                                                        <AvatarImg
                                                                            id="userImg"
                                                                            enableLetterImg={!loggedUser?.userImage?.id}
                                                                            alt={loggedUser.username}
                                                                            src={getUrl(loggedUser?.userImage?.id)}
                                                                            name={loggedUser.username}
                                                                            nameStyle={{
                                                                                fontSize: 10,
                                                                            }}
                                                                            width={20}
                                                                            height={20}
                                                                            style={{
                                                                                objectFit: 'cover',
                                                                                borderRadius: '50%',
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </>) : (
                                                                <>
                                                                    <Box>
                                                                        {getAvatar(message.senderId)}
                                                                    </Box>
                                                                    {friends.length > 1 && <Box>
                                                                        {getSenderName(message.senderId)}
                                                                    </Box>}
                                                                    <TypographyWithI18N
                                                                        i18nKey={new Date(message.createdAt).toLocaleTimeString('en-US', {
                                                                            hour: 'numeric',
                                                                            minute: 'numeric',
                                                                            hour12: true,
                                                                        })}
                                                                        fontSize={10}
                                                                        sx={{
                                                                            color: colors.text.approxSuvaGrey,
                                                                            margin: '5px 15px 5px 10px',
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        </Box>
                                                    )}
                                                    {!(conversationData?.isBlockedByOther || conversationData?.isBlocked) && showEmojiPickerFor === message.id && (
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                bottom: '100%',
                                                                right: message.senderId === loggedUser.id ? 0 : 'unset',
                                                                left: message.senderId !== loggedUser.id ? 0 : 'unset',
                                                                zIndex: 1500,
                                                                backgroundColor: colors.background.white,
                                                                borderRadius: '8px',
                                                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                                                padding: '5px 10px',
                                                                display: 'flex',
                                                                gap: '10px',
                                                            }}
                                                        >
                                                            {Object.values(EmojiReaction).map((reaction: EmojiReaction) => {
                                                                const { emoji, icon: Icon } = reactionIcons[reaction];
                                                                return (
                                                                    <Box
                                                                        key={reaction}
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            fontSize: '24px',
                                                                            '&:hover': {
                                                                                transform: 'scale(1.2)',
                                                                            },
                                                                        }}
                                                                        onClick={() => handleAddReaction(message.id, reaction)}
                                                                    >
                                                                        {
                                                                            Icon
                                                                        }
                                                                    </Box>
                                                                );
                                                            })}
                                                            <Box
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    fontSize: '24px',
                                                                    '&:hover': {
                                                                        transform: 'scale(1.2)',
                                                                    },
                                                                }}
                                                                onClick={() => { setOpenEmoji(true); setSelectedMessage(message); setShowEmojiPicker(prev => !prev) }}
                                                            >
                                                                <AddEmojiReaction />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    fontSize: '24px',
                                                                    '&:hover': {
                                                                        transform: 'scale(1.2)',
                                                                    },
                                                                }}
                                                                onClick={() => handleDeleteMessage(message.id)}
                                                            >
                                                                <PipeIcon />
                                                                <DeleteEmojiIcon />
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>)
                                    }
                                    )}
                                </Box>
                            ))}
                        </>)}
                </Box>
            )}
            {showEmojiPicker && (
                <Box
                    ref={emojiRef}
                    sx={{
                        position: 'absolute',
                        bottom: '60px',
                        right: 0,
                        zIndex: 1500,
                    }}>
                    <EmojiPicker
                        onEmojiClick={(emoji) => {
                            if (openEmoji) {
                                handleAddReaction(selectedMessage!.id, emoji.emoji);
                                setShowEmojiPicker(false);
                            }
                            else {
                                handleMessageUpdate(emoji.emoji, true)
                            }
                        }}
                        previewConfig={{ showPreview: false }}
                    />
                </Box>
            )}
            {!minimized && (
                <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
                    <Divider />
                    <Box sx={{ display: 'flex' }} justifyContent='center'>
                        {(conversationData?.isBlockedByOther || conversationData?.isBlocked) ?
                            <TypographyWithI18N
                                padding={2}
                                className='bodyText'
                                color={colors.text.darkGray}
                                i18nKey={
                                    '4um member unable to receive messages.'
                                }
                            /> : <TextField
                                id="messageTextField"
                                placeholder="Type Your Message..."
                                multiline
                                rows={2}
                                disabled={isSending}
                                error={message.length === 1900}
                                helperText={message.length === 1900 ? "Input Limit Reached" : null}
                                size='small'
                                autoComplete='off'
                                value={message}
                                onChange={(e) => handleMessageUpdate(e.target.value)}
                                variant="filled"
                                sx={{
                                    padding: '5px',
                                    '& .MuiFilledInput-root': {
                                        padding: 1
                                    },
                                    width: '-webkit-fill-available'
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end" sx={{
                                            height: '3em',
                                            maxHeight: '5em',
                                            alignItems: 'end'
                                        }}>
                                            <IconButton
                                                edge="end"
                                                onClick={() => setShowEmojiPicker(prev => !prev)}
                                            >
                                                <InsertEmoticonIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey && message.trimEnd() != '') {
                                        e.preventDefault();
                                        handleSendMessage();
                                    } else if (e.key === 'Enter' && e.shiftKey) {
                                        e.preventDefault();
                                        setMessage((prev) => prev + '\n');
                                    }
                                }}
                            />}
                        {!(conversationData?.isBlockedByOther || conversationData?.isBlocked) && message.trimEnd() != '' && <IconButton onClick={handleSendMessage}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}>
                            <SendMsgIcon />
                        </IconButton>}
                    </Box>
                </Box>
            )}
            {confirmModal?.open && (
                <ConfirmationModal
                    open={confirmModal.open}
                    message={confirmModal.message}
                    title={'Request to Delete Conversation'}
                    successLabel={'Delete'}
                    loaderBtn={confirmModal.loaderBtn}
                    confirmTxt={confirmModal.confirmTxt}
                    onSuccess={() => accountDelete(confirmModal.conversationId)}
                    onExit={() =>
                        setConfirmModal((pv) => ({
                            ...pv,
                            conversationId: confirmModal.conversationId,
                            open: false
                        }))
                    }
                />
            )}
            {reportModal.open && (
                <DialogActionWall
                    open={reportModal.open}
                    title={!reportModal.successModal ? `Report Details` : ''}
                    dialogTitleSxProps={{
                        textAlign: 'center'
                    }}
                    dialogActionsSxProps={{
                        justifyContent: 'center'
                    }}
                    closeIconButton
                    onClose={() =>
                        setReportModal((pv) => ({
                            ...pv,
                            open: false,
                            successModal: false
                        }))
                    }
                    loaderBtn={reportModal.loaderBtn}
                    onSuccess={saveReportHandler}
                    successLabel={'Send'}
                    hideBtn={!reportModal.successModal}
                    successDisabled={!selectedReason}
                    dialogSuccessSxProps={{
                        opacity: reportModal.successModal
                            ? 0
                            : reportModal.loaderBtn
                                ? 0.6
                                : 1,
                        pointerEvents: reportModal.successModal ? 'none' : ''
                    }}
                >
                    {reportModal.successModal ? (
                        <Box className='flexCenterJustify flexCenterColumn'>
                            <CheckGreenCircle />
                            <TypographyWithI18N
                                mt={3}
                                className='headerBold'
                                color={colors.text.primaryBlack}
                                i18nKey={
                                    'Your report has been submitted. Our Admin will review and take appropriate action.'
                                }
                            />
                        </Box>
                    ) : (
                        <>
                            <SelectDropdown
                                options={reason}
                                value={selectedReason}
                                onChangeEvent={handleChange}
                                selectSx={{
                                    height: 42,
                                    mb: 2,
                                    color: colors.text.darkBlack
                                }}
                            />
                            <TextField
                                placeholder='Write description'
                                multiline
                                rows={4}
                                fullWidth
                                sx={{
                                    borderRadius: 0
                                }}
                                onChange={(event) => {
                                    setReportDescription(event.target.value);
                                }}
                                inputProps={{
                                    maxLength: 1000
                                }}
                            />
                        </>
                    )}
                </DialogActionWall>
            )}
            {blockConfirmModal?.open && (
                <ConfirmationModal
                    open={blockConfirmModal.open}
                    message={blockConfirmModal.message}
                    title={
                        blockConfirmModal.confirmTxt
                            ? blockConfirmModal.title
                                ? blockConfirmModal.title
                                : blockConfirmModal.label
                            : ''
                    }
                    successLabel={blockConfirmModal.label}
                    loaderBtn={blockConfirmModal.loaderBtn}
                    confirmTxt={blockConfirmModal.confirmTxt}
                    onSuccess={blockConfirmModal.successHandler}
                    onExit={() =>
                        setBlockConfirmModal((pv) => ({
                            ...pv,
                            open: false
                        }))
                    }
                />
            )}
            <Modal
                open={showDetailedEmojiWindow}
                onClose={() => { setShowDetailedEmojiWindow(false); setSelectedReaction([]); }}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Box
                    sx={{
                        width: '40%',
                        maxWidth: '400px',
                        backgroundColor: 'white',
                        padding: 3,
                        borderRadius: '8px',
                        boxShadow: 24,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowY: 'auto',
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                        <Chip
                            label={'All ' + `(${reactionsToShow.length})`}
                            color={(selectedReaction.length > 1) ? "primary" : undefined}
                            onClick={() => setSelectedReaction(getReactions(reactionsToShow, false))} />
                        {getReactions(reactionsToShow, false).map((reaction, index) => (
                            <Chip
                                key={index}
                                color={(selectedReaction.length === 1 && selectedReaction[0]?.reaction === reaction.reaction) ? "primary" : undefined}
                                label={reaction.reaction + (reaction.count)}
                                onClick={() => setSelectedReaction([reaction])} />
                        ))}
                    </Box>
                    {selectedReaction && (
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', }}>
                                {selectedReaction.map((reaction) => reaction.users.map((userId: string, index: number) => (
                                    <>
                                        <Box display='flex' flexDirection='row' justifyContent='space-between'>
                                            <Box key={index} display='flex' flexDirection='row'>
                                                <Typography sx={{ color: '#555' }}>
                                                    {getAvatar(userId)}
                                                </Typography>
                                                <Typography sx={{ color: '#555' }}>
                                                    {getSenderFullName(userId)}
                                                </Typography>
                                            </Box>
                                            {reaction.reaction}
                                        </Box>
                                        <Divider />
                                    </>
                                )))}
                            </Box>
                        </Box>
                    )}
                </Box>
            </Modal>
        </Paper>
    );
};

export default ChatPopup;