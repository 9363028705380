import DialogActionWall from 'pages/components/dialogActionWall';
import React from 'react';
import { colors } from 'styles/colors';

export interface IPrivacyData {
  key: string;
  description: string;
}

interface IPrivacyPolicy {
  openModal: boolean;
  setOpenModal: (event: boolean) => void;
}

const PrivacyPolicy = ({ openModal, setOpenModal }: IPrivacyPolicy) => {
  return (
    <DialogActionWall
      open={openModal}
      closeIconButton
      onClose={() => setOpenModal(false)}
      title={''}
      dialogTitleSxProps={{
        textAlign: 'center'
      }}
      dialogActionsSxProps={{
        justifyContent: 'center'
      }}
      dialogPaperSxProps={{
        maxWidth: 900
      }}
    >
      <div style={{textAlign:'justify'}}>
        <div>
          <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>TERMS OF USE</strong> &nbsp;
          </p>
        </div>
        <div>
          <p>
            Welcome to 4um! We are honored to have you join us to help build a
            community based on joint ownership and joint governance where
            everyone deserves a voice and a chance to be heard. 4um LLC
            (referred to collectively as &ldquo;<strong>4um</strong>
            &rdquo;,&nbsp;the&nbsp;&ldquo;<strong>platform</strong>
            &rdquo;,&nbsp;&ldquo;<strong>our</strong>&rdquo;, &ldquo;
            <strong>us</strong>&rdquo;, or &ldquo;<strong>we</strong>&rdquo;) is
            an organization&nbsp;operating&nbsp;under the laws of
            Delaware,&nbsp;located&nbsp;at 8 The Green, Suite A, Dover, Delaware
            19901.&nbsp;The&nbsp;following Terms of Use (or&nbsp;&ldquo;
            <strong>Agreement</strong>&rdquo;) govern use of 4um. When you
            create a social media account on 4um, you agree&nbsp;that&nbsp;these
            Terms of Use constitute a binding&nbsp;Agreement between you and
            4um. The following&nbsp;Terms&nbsp;(&ldquo;<strong>Terms</strong>
            &rdquo;&nbsp;or &ldquo;<strong>Term</strong>&rdquo; in the
            singular)&nbsp;outline the responsibilities
            and&nbsp;allocate&nbsp;liability between 4um and all end users
            on&nbsp;our&nbsp;platform. Please read and make sure you understand
            our Terms of Use&nbsp;and company Policies (&ldquo;
            <strong>Policies</strong>&rdquo;)&nbsp;that govern use of 4um. If
            you do not accept or understand any&nbsp;of
            Terms&nbsp;in&nbsp;this&nbsp;Agreement&nbsp;or company Polices
            governing the use of 4um, then you&nbsp;are prohibited from using
            our platform.&nbsp;
          </p>
        </div>

        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>4um's OFFERING </strong>
          </p>
        </div>

        <div>
          <p>
            4um agrees to provide you with social media
            services.&nbsp;4um&nbsp;includes all software, services,
            technologies, features, products,
            trademarks,&nbsp;know-how,&nbsp;interactive metrics,&nbsp;and
            communications we make available to you on the platform
            (collectively, the &ldquo;<strong>Offering</strong>&rdquo;). 4um was
            created to give social media users ownership over
            their&nbsp;information, interactions, and communications resulting
            from their engagement while&nbsp;using social
            media.&nbsp;4um&rsquo;s purpose as a platform and as a community is
            to build an all-in-one social media platform that is based on a
            model of joint ownership and joint governance so that all users and
            employees have (1) an ownership stake and
            (2)&nbsp;a&nbsp;democratic&nbsp;say&nbsp;in
            how&nbsp;our&nbsp;platform&nbsp;operates&nbsp;and
            how&nbsp;their&nbsp;information is monetized.&nbsp;
            <strong>Own Your Voice</strong>!&nbsp;
          </p>
        </div>

        <div>
          <p>4um&rsquo;s overarching purpose and Offering includes: &nbsp;</p>
        </div>

        <div>
          <ul>
            <li>
              <p>
                Offering a diverse social media experience through products and
                services that allows users to&nbsp;establish, express, and
                explore ideas and subjects that epitomize who they are and what
                they care about. &nbsp;
              </p>
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li>
              <p>
                Offering users the ability to communicate and build meaningful
                connections in a shared framework based on solidarity and
                merit. &nbsp;
              </p>
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li>
              <p>
                Promotion of a healthy, secure, and all-inclusive environment
                designed with systems and teams to prevent harmful and deceptive
                behavior that violates 4um&rsquo;s Terms&nbsp;of
                Use&nbsp;or&nbsp;Policies.  &nbsp;
              </p>
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li>
              <p>
                Incorporating user feedback and technologies
                (i.e.&nbsp;artificial intelligence and machine learning)
                that&nbsp;assist&nbsp;in the evolution, security, performance,
                and betterment of 4um and the global community we serve. &nbsp;
              </p>
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li>
              <p>
                Collection, storage, and analysis of user data, based on your
                interactions with 4um, to better serve our community, help
                members communicate, discover, and&nbsp;participate&nbsp;in
                meaningful&nbsp;social experiences,&nbsp;comply with&nbsp;legal
                and regulatory requirements, and to promote the
                sponsored&nbsp;Content that matters&nbsp;the&nbsp;most to
                you. &nbsp;
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div style={{textAlign:'justify'}}>
        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>PRIVACY POLICY</strong>&nbsp;
          </p>
        </div>

        <div>
          <p>
            4um is made possible through advertisements, subscriptions, paid
            offerings, and donations. We will
            collect&nbsp;and&nbsp;utilize&nbsp;the information you
            provide&nbsp;us&nbsp;by accessing and using
            our&nbsp;platform.&nbsp;4um&rsquo;s Privacy Policy summarizes the
            protocols that our&nbsp;platform employs for
            gathering,&nbsp;utilizing, and&nbsp;disseminating&nbsp;user
            information to sponsors and all members across our community and how
            users can manage the information they share on
            our&nbsp;platform.&nbsp;BY USING 4um&nbsp;ALL USERS AGREE TO OUR
            PRIVACY POLICY.&nbsp;
          </p>
        </div>

        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>WHO MAY USE 4um</strong> &nbsp;
          </p>
        </div>

        <div style={{textAlign:'justify'}}>
          <p>For all users of 4um, the following stipulations apply:&nbsp;</p>
        </div>

        <div style={{textAlign:'justify'}}>
          <ol id='1'>
            <li>
              <p>Users must be 13 years of older to use 4um.  &nbsp;</p>
            </li>
            <li>
              <p>
                Anyone under 18 using 4um confirms that they have obtained
                permission from a parent or legal guardian to do so. Any user
                under 18 further confirms said parent or legal guardian
                understands that by allowing their child to use 4um they are
                bound by&nbsp;the&nbsp;Terms of&nbsp;Use and Policies governing
                4um&nbsp;and responsible for their child's actions on
                4um. &nbsp;
              </p>
            </li>
            <li>
              <p>
                If you are utilizing 4um on behalf of a business, company, or
                organization, you confirm that said entity accepts these Terms
                of&nbsp;Use&nbsp;and that you have the aegis to act on said
                entity&rsquo;s behalf. &nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not use 4um if you are barred from using&nbsp;our
                Offering under applicable laws or regulations. &nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not use 4um if your account
                was&nbsp;terminated&nbsp;because of infractions of our Terms
                of&nbsp;Use&nbsp;or any applicable laws. &nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not use 4um if you are a convicted sex offender. &nbsp;
              </p>
            </li>
          </ol>
        </div>
      </div>
      <div style={{textAlign:'justify'}}>
        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>RESTRICTIONS</strong> &nbsp;
          </p>
        </div>

        <div>
          <p>
            We want to build a community based on joint ownership and joint
            governance where everyone has an equal&nbsp;chance&nbsp;to be heard.
            To ensure the integrity and wellbeing of our community, the
            following restrictions apply: &nbsp;
          </p>
        </div>

        <div>
          <ol id='1'>
            <li>
              <p>
                You may not engage in any actions that breach 4um&rsquo;s
                Terms&nbsp;of Use&nbsp;or&nbsp;Policies. &nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not engage in any actions that impair&nbsp;the
                functionality of our platform&nbsp;or our Offering. This
                includes: (i)&nbsp;using fake accounts, bots, or paying people
                to artificially inflate&nbsp;likes, followers, views, comments,
                or other user metrics in any manner that&nbsp;could&nbsp;cause
                inauthentic user engagement; (ii)&nbsp;submitting frivolous,
                unsubstantiated, or irksome submissions that disrupt or exploit
                our complaint, reporting, or appeals process; or
                (iii)&nbsp;circumventing, disabling, or interfering
                with&nbsp;security features.&nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not use botnets, robots, scrappers,
                or&nbsp;any&nbsp;other means to pull
                information&nbsp;from&nbsp;our platform for commercial means
                that do not&nbsp;benefit&nbsp;our community.&nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not use the platform to sell&nbsp;or promote&nbsp;any
                advertising, marketing,&nbsp;contests,&nbsp;sponsorships,
                product placements, or promotions to members of our community
                unless you receive prior written consent&nbsp;from us&nbsp;to do
                so.&nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not use our platform to
                spam&nbsp;or&nbsp;disseminate&nbsp;unsolicited commercial or
                promotional&nbsp;Content.&nbsp;&nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not engage in disingenuous, deceitful, or unlawful
                activities on our platform.&nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not&nbsp;violate intellectual property rights&nbsp;on
                our platform.&nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not violate the legal rights of others&nbsp;on our
                platform.&nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not&nbsp;post confidential or&nbsp;private
                information&nbsp;of others without their consent&nbsp;on our
                platform.&nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not sell, purchase, or license user accounts or data
                from our platform without obtaining written permission from us
                to do so.&nbsp;
              </p>
            </li>
            <li>
              <p>
                You may not access, copy,&nbsp;disseminate, modify, or sell any
                of&nbsp;the&nbsp;services&nbsp;in our
                Offering&nbsp;or&nbsp;Content&nbsp;contained&nbsp;therein&nbsp;unless
                (i) expressly authorized or (ii) you have received prior written
                consent from us to do so.&nbsp;
              </p>
            </li>
          </ol>
        </div>

        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>USE OF OUR OFFERING</strong>&nbsp;
          </p>
        </div>

        <div>
          <p>
            The uploading of audio, images, photos, videos, and text
            (i.e.&nbsp;&ldquo;<strong>Content</strong>&rdquo;)&nbsp;on
            4um&nbsp;is the responsibility of the user.&nbsp;As a user you are
            legally responsible for all&nbsp;Content&nbsp;that&nbsp;you upload
            on&nbsp;our&nbsp;platform. Users may upload and
            access&nbsp;Content&nbsp;so&nbsp;long as they&nbsp;comply
            with&nbsp;our Terms of Use and&nbsp;Polices. 4um is under no binding
            obligation to host&nbsp;Content uploaded&nbsp;by users and we may
            remove&nbsp;Content and user profiles from our platform if it
            violates our Terms of Use or&nbsp;Policies. If users believe
            certain&nbsp;Content violates
            the&nbsp;Policies&nbsp;governing&nbsp;our platform, they may
            report&nbsp;said violations&nbsp;to us by flagging it or sending an
            email&nbsp;to&nbsp;
            <u style={{ color: colors.text.primaryTheme, textDecoration: 'none' }}>admin@4um.com</u>
            . Using our platform does not grant users ownership or legal rights
            to any&nbsp;Content or other part of our Offering.&nbsp;
          </p>
        </div>

        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>YOU AGREE TO</strong>&nbsp;
          </p>
        </div>

        <div>
          <p>
            Under our Terms of&nbsp;Use,&nbsp;by using&nbsp;4um&nbsp;users agree
            to&nbsp;the following&nbsp;stipulations&nbsp;to help us build our
            platform and better serve our community:&nbsp;
          </p>
        </div>

        <div>
          <ol id='1'>
            <li>
              <p>
                Users&nbsp;grant us&nbsp;a&nbsp;license&nbsp;to
                all&nbsp;Content&nbsp;they&nbsp;upload on our platform. This
                includes any&nbsp;Content that you post, share, or upload such
                as audio, pictures, text, or videos. While
                users&nbsp;remain&nbsp;owners of the&nbsp;Content they post,
                they endow us with a conveyable,&nbsp;free from royalty, global,
                nonexclusive, sublicensable license to&nbsp;disseminate,
                duplicate, host, publicly&nbsp;perform or
                display,&nbsp;transmit,&nbsp;create derivative
                works,&nbsp;or&nbsp;utilize&nbsp;their&nbsp;Content&nbsp;in
                connection with our&nbsp;Offering. This license&nbsp;will
                continue for a commercial&nbsp;reasonably time&nbsp;from the
                date your remove the&nbsp;Content
                from&nbsp;4um&nbsp;or&nbsp;from&nbsp;when your account
                is&nbsp;deleted.&nbsp;
              </p>
            </li>
            <li>
              <p>
                Users grant a conveyable, free from royalty, global,
                nonexclusive, sublicensable license to all other users to access
                and&nbsp;utilize&nbsp;your&nbsp;Content via our platform.&nbsp;
              </p>
            </li>
            <li>
              <p>
                Users&nbsp;grant&nbsp;us&nbsp;the right&nbsp;to use
                their&nbsp;interactions&nbsp;on our platform, profile picture,
                and username to tailor and enhance advertisements,
                sponsored&nbsp;Content, and interactions with other
                accounts.&nbsp;While we will never&nbsp;disclose&nbsp;or sell
                your personal data (including name, email, or address), we may
                use demographics gained from our to&nbsp;Offering to share the
                advertisements and marketing materials that are most relevant to
                you.&nbsp;
              </p>
            </li>
            <li>
              <p>
                Users agree that we can install updates to our Offering on your
                device. &nbsp;
              </p>
            </li>
          </ol>
        </div>

        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>
              CONTENT REMOVAL, ACCOUNT RESTRICTION ACCOUNT SUSPENSION, &amp; BAN
            </strong>
            &nbsp;
          </p>
        </div>

        <div>
          <p>
            4um reserves the right to remove any&nbsp;Content from our platform,
            restrict&nbsp;functionalities of your account,&nbsp;suspend your
            account, or permanently&nbsp;ban you from our platform if (i) you
            violate our Terms of&nbsp;Use&nbsp;or&nbsp;any of
            our&nbsp;company&nbsp;Polices,&nbsp;(ii) if&nbsp;we believe that
            your behavior causes&nbsp;or could cause harm or liability, risk, or
            legal exposure to our platform, any of the users on our platform, or
            any other parties, or&nbsp;(iii) to comply with a legal requirement
            or court order. If&nbsp;Content from your profile is removed, your
            account is restricted or suspended, or if you are permanently banned
            from our platform, you will be notified&nbsp;the rational&nbsp;for
            our actions unless doing so:&nbsp;
          </p>
        </div>

        <div>
          <ol id='1'>
            <li>
              <p>
                Would cause harm to other users or third&nbsp;parties;&nbsp;
              </p>
            </li>
            <li>
              <p>Would risk legal liability to&nbsp;4um;&nbsp;</p>
            </li>
            <li>
              <p>
                Would violate the law or&nbsp;explicit&nbsp;orders of
                law&nbsp;enforcement;&nbsp;
              </p>
            </li>
            <li>
              <p>
                Would compromise an&nbsp;ongoing&nbsp;investigation by law
                enforcement; or&nbsp;
              </p>
            </li>
            <li>
              <p>Would impair the functionality of our&nbsp;Offering.&nbsp;</p>
            </li>
          </ol>
        </div>

        <div>
          <p>
            If&nbsp;Content from your profile is removed, your account is
            restricted or suspended, or if you are permanently banned from our
            platform, you may appeal our decision by sending an email&nbsp;
            <u style={{ color: colors.text.primaryTheme, textDecoration: 'none' }}>admin@4um.com</u>
            &nbsp;and providing the rational for your appeal.&nbsp;
          </p>
        </div>

        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>ACCOUNT DELETION</strong>&nbsp;
          </p>
        </div>
      </div>
      <div style={{textAlign:'justify'}}>
        <div>
          <p>
            Users may&nbsp;delete&nbsp;their account at any time by going to
            settings and choosing the option to&nbsp;delete&nbsp;their profile
            from our platform.&nbsp;Upon&nbsp;your request, we will begin the
            deletion process. During the deletion process,
            your&nbsp;Content&nbsp;will no&nbsp;longer&nbsp;accessible&nbsp;on
            our platform but&nbsp;will&nbsp;continue to be governed by
            our&nbsp;Terms of Use&nbsp;and Policies.&nbsp;The deletion process
            will take no longer than&nbsp;thirty (30)&nbsp;days&nbsp;to
            complete,&nbsp;though&nbsp;your information
            may&nbsp;be&nbsp;retained&nbsp;in&nbsp;our data servers
            for&nbsp;a&nbsp;period not to exceed&nbsp;one hundred twenty
            (120)&nbsp;days following&nbsp;your&nbsp;request for deletion. Under
            the following circumstances, your data&nbsp;may
            be&nbsp;retained&nbsp;for longer than&nbsp;one hundred twenty
            (120)&nbsp;days:&nbsp;
          </p>
        </div>

        <div>
          <ol id='1'>
            <li>
              <p>
                Where deletion of your data would impair
                our&nbsp;capacity&nbsp;to&nbsp;comply with&nbsp;legal requests
                or examine violations or potential violations of our Terms
                of&nbsp;Use&nbsp;or&nbsp;Policies;&nbsp;
              </p>
            </li>
            <li>
              <p>
                Where technical restrictions prevent us from fulfilling
                deletion&nbsp;of your&nbsp;account;&nbsp;
              </p>
            </li>
            <li>
              <p>
                Where your&nbsp;Content or information&nbsp;is being&nbsp;used
                by other users on our platform.&nbsp;
              </p>
            </li>
          </ol>
        </div>

        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>ARBITRATION</strong>&nbsp;
          </p>
        </div>

        <div>
          <p>
            ALL USERS OF 4um HEREBY AGREE THAT ANY
            DISPUTES&nbsp;HEREUNDER&nbsp;WILL BE SETTLED&nbsp;IN DOVER,
            DELAWARE&nbsp;THROUGH INDIVIUDAL ARBITRATION THAT IS BINDING AND
            FINAL&nbsp;IN ACCORDANCE WITH THE RULES OF THE AMERICAN ARBITRATION
            ASSOCIATION. Users hereby waive a trial by jury and their right
            to&nbsp;participate&nbsp;in any class-wide arbitration or class
            action lawsuit involving 4um. The arbitrator may, in his or her sole
            discretion,&nbsp;allocate&nbsp;all or part of the costs of the
            arbitration in the award, including the fees of the arbitrator and
            the reasonable attorneys&rsquo; fees of the parties.&nbsp;Prior
            to&nbsp;commencing&nbsp;a case before an arbitrator, you must send
            us a written Notice of Dispute that includes your name, username,
            home address, email address, phone number, a thorough explanation of
            the dispute,&nbsp;and the outcome that you&nbsp;seek&nbsp;to:&nbsp;
          </p>
        </div>

        <div>
          <p>4um Arbitration Committee&nbsp;</p>
        </div>
        <div>
          <p>8 The Green, Suite A&nbsp;</p>
        </div>
        <div>
          <p>Dover, DE 19901&nbsp;</p>
        </div>
        <div>
          <p> &nbsp;</p>
        </div>
        <div>
          <p>
            We will&nbsp;notify you&nbsp;by&nbsp;your email&nbsp;or mail that we
            have received your Notice of Dispute and&nbsp;attempt&nbsp;to
            resolve the dispute outside of arbitration. If after sixty (60) days
            from receipt of your Notice of Dispute we have not reached a
            settlement, you or we may&nbsp;proceed&nbsp;to arbitration. If there
            is a final judicial ruling that a specific claim (or entreaty for a
            specific outcome) under this agreement cannot be arbitrated, then
            that claim, and only that claim (or entreaty for that specific
            outcome), may be brought to court.&nbsp;You agree&nbsp;(i)&nbsp;that
            all claims or entreaties to be adjudicated outside of arbitration
            must be adjudicated exclusively in the US District Court for the
            State of Delaware,&nbsp;(ii)&nbsp;to defer to&nbsp;the&nbsp;personal
            jurisdiction of the US District Court for the State of Delaware to
            litigate any such claim or entreaty, and&nbsp;(iii)&nbsp;that the
            laws of the State of Delaware will govern this Agreement and any
            such claim or entreaty. You also agree, without prejudice to
            the&nbsp;preceding, that 4um may&nbsp;bring a&nbsp;claim
            or&nbsp;petition&nbsp;against you&nbsp;in court&nbsp;for violations
            of this Agreement.&nbsp;
          </p>
        </div>

        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>LIMITATION ON LEGAL ACTIONS</strong>&nbsp;
          </p>
        </div>
      </div>
      <div style={{textAlign:'justify'}}>
        <div>
          <p>
            ALL USERS AGREE THAT ANY CAUSE OF ACTION RESULTING FROM OR CONNECTED
            TO THE PLATFORM&rsquo;S OFFERING,&nbsp;TERMS OF&nbsp;USE, OR
            POLICIES&nbsp;MUST BE INITIATED WITHIN ONE (1) YEAR
            FOLLOWING&nbsp;THE&nbsp;DATE&nbsp;THAT&nbsp;THE CAUSE OF ACTION
            COMES INTO EXISTENCE. ANY CAUSE OF ACTION THAT IS NOT INITIATED
            WITHIN ONE (1) YEAR FROM&nbsp;THE&nbsp;DATE IT COMES INTO EXISTENCE
            IS FOREVERMORE&nbsp;DISQUALIFIED. &nbsp;
          </p>
        </div>

        <div>
        <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
            <strong>MISCENLANEOUS PROVISIONS</strong>&nbsp;
          </p>
        </div>

        <div>
          <p>
            <strong>Disclaimer of Warranty:&nbsp;</strong>4um is provided
            &ldquo;As&nbsp;Is&rdquo; and we do not make any&nbsp;explicit
            commitments,&nbsp;guarantees, or warranties&nbsp;regarding&nbsp;our
            Offering.&nbsp;
          </p>
        </div>

        <div>
          <p>
            <strong>Governing Law:&nbsp;</strong>This Agreement will be governed
            by and construed&nbsp;in accordance with&nbsp;the&nbsp;State laws of
            Delaware in the United States of America applicable to agreements
            made and to be fully performed&nbsp;therein.&nbsp;
          </p>
        </div>

        <div>
          <p>
            <strong>Indemnification:&nbsp;</strong>Users of 4um agree to defend,
            indemnify and hold harmless 4um, its affiliates and their respective
            control persons, managers, members, stockholders, officers,
            employees and agents (each an&nbsp;&ldquo;
            <strong>Indemnified Party</strong>&rdquo;) to the fullest legal
            extent from and against any and all claims, losses, damages,
            liabilities, penalties, judgments, costs of settlement, awards,
            costs and expenses as incurred (including&nbsp;if we remove
            your&nbsp;Content or delete your account and&nbsp;all reasonable
            fees and disbursements of counsel) (collectively&nbsp;&ldquo;
            <strong>Losses</strong>&rdquo;), arising out of or related to: (i)
            your use of and access to our Offering; (ii) your violation of any
            terms of this Agreement or of our Policies; (iii) your breach of any
            third party right, including any intellectual property or privacy
            right; or (iv) any allegation that your Content resulted in damages
            to a third party. This indemnity provision will
            survive&nbsp;termination of&nbsp;this Agreement and your use
            of&nbsp;our Offering. &nbsp;
          </p>
        </div>

        <div>
          <p>
            <strong>Interpretation:&nbsp;</strong>Within these
            Terms&nbsp;of&nbsp;Use&nbsp;and our other Policies, the terminology
            &ldquo;include&rdquo; or &ldquo;including&rdquo; is defined to mean
            &ldquo;including but not limited to.&rdquo;&nbsp;
          </p>
        </div>

        <div>
          <p>
            <strong>Limitation of Liability:&nbsp;</strong>4um, its affiliates
            and their respective control persons, managers, members,
            stockholders, officers,&nbsp;employees&nbsp;and agents will not be
            responsible for any loss of business opportunities, goodwill
            profits, revenues; corruption or loss of data; or punitive damages
            caused by:&nbsp;
          </p>
        </div>

        <div>
          <ol id='1'>
            <li>
              <p>Any Content found within our&nbsp;Offering;&nbsp;</p>
            </li>
            <li>
              <p>
                The removal or absence of any Content&nbsp;from
                our&nbsp;Offering;&nbsp;
              </p>
            </li>
            <li>
              <p>Any disturbance or stoppage of our&nbsp;Offering;&nbsp;</p>
            </li>
            <li>
              <p>Any errors in our&nbsp;Offering;&nbsp;</p>
            </li>
            <li>
              <p>Any injury or damage cause by our&nbsp;Offering;&nbsp;</p>
            </li>
            <li>
              <p>
                Any illicit access to or&nbsp;us&nbsp;of our Offering.&nbsp;
              </p>
            </li>
          </ol>
        </div>
      </div>

      <div>
        <p>
          This Limitation of Liability applies to any allegation, regardless of
          the legal basis for its assertion.&nbsp;
        </p>
      </div>

      <div style={{textAlign:'justify'}}>
        <p>
          <strong>No Waiver:&nbsp;</strong>If you fail to comply with this
          Agreement, any failure or delay in the exercise of any right,
          power,&nbsp;or privilege provided to 4um under this Agreement shall
          not of itself amount to any waiver thereof,&nbsp;and any individual or
          partial exercise of said rights, powers or privileges shall not
          represent any waiver for us to exercise that right, power,&nbsp;or
          privilege in the future.&nbsp;
        </p>
      </div>

      <div style={{textAlign:'justify'}}>
        <p>
          <strong>Severability:&nbsp;</strong>If any&nbsp;Term or
          provision&nbsp;herein&nbsp;is invalid or unenforceable for any reason,
          such invalidity or unenforceability will not affect the validity or
          enforceability of other&nbsp;Terms&nbsp;or provisions&nbsp;under this
          Agreement.&nbsp;
        </p>
      </div>

      <div style={{textAlign:'justify'}}>
        <p>
          <strong>Third</strong>&nbsp;Party Offerings:&nbsp;Our Offering
          may&nbsp;contain&nbsp;links to third&nbsp;party links,
          services,&nbsp;urls,&nbsp;or websites which are not under the control
          or ownership of 4um.&nbsp;We assume no responsibility, legal or
          otherwise, for such links, services,&nbsp;urls,&nbsp;or
          websites.&nbsp;
        </p>
      </div>

      <div style={{textAlign:'justify'}}>
        <p>
          <strong>Third</strong>&nbsp;Party Rights: This Agreement
          does&nbsp;not&nbsp;provide rights to any third parties. Users may not
          transfer&nbsp;any of&nbsp;their rights or obligations hereunder
          without first obtaining our written consent. 4um&rsquo;s rights and
          obligations hereunder may be transferred to our respective successors
          and&nbsp;permitted&nbsp;assigns (including in the instance of
          acquisition, merger, or sale).&nbsp;
        </p>
      </div>

      <div>
      <p style={{ textAlign: 'center' , color: colors.text.primaryTheme}}>
          <strong>CHANGES TO THESE TERMS</strong>&nbsp;
        </p>
      </div>

      <div style={{textAlign:'justify'}}>
        <p>
          4um reserves the right to&nbsp;modify&nbsp;our Offering, our Terms
          of&nbsp;Use,&nbsp;or Policies to better serve our
          community&nbsp;and&nbsp;achieve the platform&rsquo;s overarching
          purpose. If 4um makes changes to&nbsp;our Offering, our Terms
          of&nbsp;Use, or Policies&nbsp;we will&nbsp;notify you&nbsp;via the
          platform. If you do not consent to
          4um&rsquo;s&nbsp;Term&nbsp;of&nbsp;Use&nbsp;or Polices&nbsp;you
          may&nbsp;delete&nbsp;your account at any time. Continued use of the
          platform will be tacit acceptance&nbsp;that you&nbsp;abide
          by&nbsp;the&nbsp;Terms&nbsp;of&nbsp;Use&nbsp;and
          Polices&nbsp;governing 4um and the community we serve. &nbsp;
        </p>
        <p>
          Effective as of July 4, 2024.
        </p>
      </div>
    </DialogActionWall>
  );
};

export default PrivacyPolicy;
