import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Button, TextField, InputAdornment, Snackbar, Alert } from '@mui/material';
import { BackArrowIcon } from 'assets/icon';
import { ApiPaths } from 'models/apiPaths';
import { axiosPostFriendsList, fetchUserDetails } from 'services/api';
import { IFriends } from 'models/userProfile';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import { colors } from 'styles/colors';
import { CloseRoundIcon, SearchIcon } from 'assets';
import AvatarImg from 'pages/components/avatarImg';
import { getUrl } from 'utilities/userImageURL';
import { Check } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import GroupsSkeleton from '__mocks__/skeletonLoading/groupsLoader';

interface IAddParticipantsProps {
    onClose: (selectedFriends: any[]) => void;
    existingParticipantIds: string[];
    selectedFriends: IFriends[];
}

const AddParticipants: React.FC<IAddParticipantsProps> = ({ onClose, existingParticipantIds, selectedFriends: parentSelectedFriends }) => {
    const [scroll, setScroll] = useState<boolean>(true);
    const [friendsData, setFriendsData] = useState<any[]>([]);
    const [selectedUsers, setSelectedUsers] = React.useState<any[]>([]);
    const [pageChange, setPageChange] = useState({
        previousPageCursor: '',
        nextPageCursor: '',
        nextPage: true
    });
    const [loader, setLoader] = useState<boolean>(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [userId, setUserId] = useState<string>("");

    const handleUserSelection = (user: any) => {
        setSelectedUsers((prevSelected) => {
            const userExists = prevSelected.some((selectedUser) => selectedUser.user.id === user.user.id);

            if (userExists) {
                return prevSelected.filter((selectedUser) => selectedUser.user.id !== user.user.id);
            } else {
                if (prevSelected.length >= 6) {
                    setDialogOpen(true);
                    return prevSelected;
                }
                return [...prevSelected, user];
            }
        });
    };

    const getFriendsList = async (user: any) => {
        if (pageChange?.nextPage) {
            setLoader(true);
            const payload = {
                size: 20,
                nextPageCursor: pageChange?.nextPageCursor ?? ''
            };
            await axiosPostFriendsList(payload, user)
                .then((res: IFriends) => {
                    if (res) {
                        setPageChange({
                            previousPageCursor: res.previousPageCursor,
                            nextPageCursor: res.nextPageCursor,
                            nextPage: res.nextPageCursor ? true : false
                        });
                        setFriendsData((pv) => [...pv, ...res.content]);

                        const preselectedUsers = res.content.filter((user) =>
                            existingParticipantIds.includes(user.user.id)
                        );

                        setSelectedUsers((prevSelected) => {
                            const updatedSelectedUsers = [...prevSelected];
                            preselectedUsers.forEach(user => {
                                if (!updatedSelectedUsers.some(selected => selected.user.id === user.user.id)) {
                                    updatedSelectedUsers.push(user);
                                }
                            });
                            return updatedSelectedUsers;
                        });
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    };

    useEffect(() => {
        fetchUserDetails(ApiPaths.ME).then((data) => {
            setUserId(data.id);
            getFriendsList(data.id);
        });
    }, []);


    const handleSelectButton = () => {
        onClose(selectedUsers);
    };

    const handleBackButton = () => {
        onClose(parentSelectedFriends);
    };

    useEffect(() => {
        setSelectedUsers(parentSelectedFriends);
    }, [parentSelectedFriends]);


    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 2,
                borderBottom: `1px solid ${colors.border.primary}`
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>
                    <IconButton onClick={handleBackButton}>
                        <BackArrowIcon />
                    </IconButton>
                    <TypographyWithI18N
                        className='smallHeader'
                        i18nKey={'Select Friends'}
                        sx={{
                            color: colors.text.primaryBlack,
                            whiteSpace: 'nowrap'
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{ overflowX: 'auto' }}>
                <InfiniteScroll
                    dataLength={friendsData?.length}
                    next={() => getFriendsList(userId)}
                    hasMore={pageChange?.nextPage}
                    loader={loader && <GroupsSkeleton showSubLine={1} />}
                    scrollableTarget='scrollToElement'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16
                    }}
                    height={400}
                >
                    <Box sx={{ padding: 2 }}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                                gap: '40px',
                            }}
                        >
                            {friendsData.map((user, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        position: 'relative',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleUserSelection(user)}
                                >
                                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                                        <AvatarImg
                                            key={index}
                                            id='userImg'
                                            enableLetterImg={user.user.imageId ? false : true}
                                            alt={user.username}
                                            src={getUrl(user.user.imageId)}
                                            name={user.user.username}
                                            width={50}
                                            height={50}
                                            style={{
                                                objectFit: 'cover',
                                                borderRadius: '50%',
                                                border: `2px solid ${colors.border.primary}`,
                                            }}
                                        />
                                        {selectedUsers.some((selectedUser) => selectedUser.user.id === user.user.id) && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    backgroundColor: 'green',
                                                    color: 'white',
                                                    borderRadius: '50%',
                                                    width: 20,
                                                    height: 20,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: `2px solid ${colors.background.lightCyan}`,
                                                }}
                                            >
                                                <Check fontSize="small" />
                                            </Box>
                                        )}
                                    </Box>
                                    <Typography sx={{ marginTop: 1 }}>{user.user.username}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </InfiniteScroll>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
                <Button variant="contained" color="primary" sx={{ mr: 2 }} onClick={handleSelectButton}>
                    Select
                </Button>
                <Button variant="outlined" onClick={handleBackButton}>
                    Cancel
                </Button>
            </Box>
            {dialogOpen &&
                <Snackbar open={dialogOpen} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} autoHideDuration={2000} onClose={() => setDialogOpen(false)}>
                    <Alert
                        onClose={() => setDialogOpen(false)}
                        severity="error"
                        variant="filled"
                        sx={{ width: '60%' }}
                    >
                        You already added 7 members to the conversation. Adding more members is not allowed.
                    </Alert>
                </Snackbar>
            }
        </Box>
    );
};

export default AddParticipants;