import * as React from 'react';
import { Breakpoint, SxProps } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AdminButton from './button';
import TypographyWithI18N from './TypographyWithI18N';
import { colors } from 'styles/colors';
import { Box, CircularProgress } from '@mui/material';

interface IDialogActionWall extends DialogProps {
  id?: string;
  open: boolean;
  size?: string;
  dividers?: boolean;
  closeIconButton?: boolean;
  dialogTitleSxProps?: SxProps;
  dialogActionsSxProps?: SxProps;
  dialogPaperSxProps?: SxProps;
  dialogContentSxProps?: SxProps;
  dialogSuccessSxProps?: SxProps;
  footerContent?: React.ReactNode;
  headerContent?: React.ReactNode;
  onCancel?: () => void;
  onSuccess?: () => void;
  onClose?: () => void;
  title?: string;
  successDisabled?: boolean;
  onSuccessBtnColor?:
    | 'primary'
    | 'inherit'
    | 'error'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning';
  cancelLabel?: string;
  successLabel?: string;
  loaderBtn?: boolean;
  hideBtn?: boolean;
}

const DialogActionWall: React.FC<IDialogActionWall> = ({
  id,
  title,
  cancelLabel,
  successLabel,
  successDisabled,
  open = false,
  children,
  size,
  dividers,
  closeIconButton,
  onSuccessBtnColor = 'primary',
  headerContent,
  footerContent,
  onCancel,
  onSuccess,
  onClose,
  dialogTitleSxProps,
  dialogActionsSxProps,
  dialogPaperSxProps,
  dialogContentSxProps,
  dialogSuccessSxProps,
  loaderBtn,
  hideBtn = true,
}: IDialogActionWall) => {
  return (
    <React.Fragment>
      <Dialog
        id={id}
        data-testid={id}
        open={open}
        maxWidth={size ? (size as Breakpoint) : 'sm'}
        fullWidth
        PaperProps={{
          elevation: 0,
          sx: {
            pt: 3,
            pb: onCancel || onSuccess ? 3 : 0,
            ...dialogPaperSxProps
          }
        }}
      >
        <DialogTitle
          role='presentation'
          sx={{ p: 0, mb: 2, px: 3, ...(dialogTitleSxProps ?? {}) }}
        >
          {headerContent ? headerContent : <></>}
          {title && (
            <TypographyWithI18N
              i18nKey={title}
              className='smallHeaderBold'
              color={'#282828'}
            />
          )}
          {closeIconButton && (
            <IconButton
              aria-label='close'
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>

        <DialogContent
          dividers={dividers}
          sx={{
            p: 2,
            px: 8,
            ...dialogContentSxProps
          }}
        >
          {children}
        </DialogContent>
        <DialogActions
          sx={{
            py: 0,
            px: onCancel || onSuccess ? 3 : 0,
            ...dialogActionsSxProps
          }}
        >
          {footerContent ? footerContent : <></>}
          {onSuccess && hideBtn && (
            <Box sx={{ position: 'relative' }}>
              <AdminButton
                title={successLabel || 'ok'}
                id={id}
                data-testid={id}
                role='presentation'
                aria-label={`Button Press Enter to `}
                color={onSuccessBtnColor}
                onClick={onSuccess}
                disabled={successDisabled}
                sxProps={{
                  width: 164,
                  height: 44,
                  ...dialogSuccessSxProps
                }}
              />
              {loaderBtn && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    left: '45%',
                    top: '25%'
                  }}
                />
              )}
            </Box>
          )}
          {onCancel && hideBtn && (
            <AdminButton
              title={cancelLabel || 'cancel'}
              data-testid={id}
              id={id}
              onClick={onCancel}
              variant='outlined'
              role='presentation'
              aria-label={` Button Press Enter to Exit the Window`}
              sxProps={{
                width: 164,
                height: 44,
                '&.MuiButton-outlined': {
                  border: `1px solid ${colors.text.approxVeryLightGrey}`,
                  background: colors.text.approxWhiteSmoke,
                  color: colors.text.darkGray
                }
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogActionWall;
