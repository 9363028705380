import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFriendData: null
};

const friendSlice = createSlice({
  name: 'friendList',
  initialState,
  reducers: {
    setFriendData: (state, action) => {
      state.isFriendData = action.payload.isFriendData;
    }
  }
});

export const { setFriendData } = friendSlice.actions;

export default friendSlice.reducer;
