import React from 'react';
import DialogActionWall from './dialogActionWall';
import { Box } from '@mui/material';
import { CheckGreenCircle } from 'assets';
import TypographyWithI18N from './TypographyWithI18N';
import { colors } from 'styles/colors';

interface IConfirmationModal {
  open: boolean;
  title?: string;
  loaderBtn?: boolean;
  successLabel?: string;
  confirmTxt?: boolean;
  message?: string;
  onSuccess?: () => void;
  onExit?: () => void;
  icon?: JSX.Element;
}

const ConfirmationModal = ({
  open,
  title,
  loaderBtn,
  successLabel,
  confirmTxt = true,
  message,
  icon,
  onSuccess,
  onExit
}: IConfirmationModal) => {
  return (
    <DialogActionWall
      open={open}
      title={confirmTxt ? title : ''}
      dialogTitleSxProps={{
        textAlign: 'center'
      }}
      dialogActionsSxProps={{
        justifyContent: 'center'
      }}
      onSuccess={onSuccess}
      onCancel={onExit}
      onSuccessBtnColor={'error'}
      loaderBtn={loaderBtn}
      successLabel={successLabel}
      dialogSuccessSxProps={{
        opacity: loaderBtn ? 0.6 : 1
      }}
      hideBtn={confirmTxt}
      closeIconButton={!confirmTxt}
      onClose={onExit}
    >
      <Box className='flexCenterJustify flexCenterColumn'>
        {!confirmTxt ? icon ? icon : <CheckGreenCircle /> : null}
        <TypographyWithI18N
          mt={!confirmTxt ? 3 : 0}
          textAlign={'center'}
          sx={{ whiteSpace: 'pre-wrap' }}
          className={!confirmTxt ? 'headerBold' : ''}
          color={colors.text.primaryBlack}
          i18nKey={message || 'Success'}
        />
      </Box>
    </DialogActionWall>
  );
};

export default ConfirmationModal;
