import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from 'store';
import isTokenExpired from './authTokenValidation';
import { setLogIn } from 'store/authSlice';

export const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

const httpClient = axiosClient;

export const BASE_API = process.env.REACT_APP_BASE_API;
export const BASE_API_POST = process.env.REACT_APP_BASE_API_POST;
export const BASE_API_INTERACTION = process.env.REACT_APP_BASE_API_INTERACTION;
export const BASE_API_NOTIFICATION = process.env.REACT_APP_BASE_API_NOTIFICATION;
export const BASE_API_CHAT = process.env.REACT_APP_BASE_API_CHAT

export const StaticGroupId = {
  groupId: 'e190d53a-6c34-42db-81b2-d3a694cbe73e'
};

async function axiosResponseErrorHandler(error: AxiosError) {
  const axiosRequest = error?.request;
  const axiosResponse = error?.response;
  const isAxiosResponseValid = (axiosRes: any) => axiosRes;
  const isAxiosRequestValid = (axiosReq: any) => axiosReq;

  if (
    isAxiosRequestValid(axiosRequest) &&
    isAxiosResponseValid(axiosResponse)
  ) {
    return Promise.reject(error);
  }
}

async function generateFinalUrl(
  path: string,
  apiUrl?: string
): Promise<string> {
  const finalUrl: string = `${apiUrl || BASE_API_POST}/${path}`;
  return finalUrl;
}

axiosClient.interceptors.request.use(async (config) => {
  const userAuth = await store.getState().userAuth;
  const idToken = userAuth?.authToken;
  const dispatch = store.dispatch;
  const isTokenExp = isTokenExpired(idToken);
  isTokenExp &&
    dispatch(
      setLogIn({
        authToken: null,
        isLoggedIn: false
      })
    );
  const tokenAuth = idToken;
  if (!config.headers.Authorization && tokenAuth && !isTokenExp) {
    config.headers.Authorization = tokenAuth;
  }
  return config;
});

axiosClient?.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    await axiosResponseErrorHandler(error);
  }
);

export async function axiosGet<R = unknown>(
  path: string,
  config?: AxiosRequestConfig | undefined,
  apiUrl?: string
): Promise<AxiosResponse<R>> {
  const finalUrl = await generateFinalUrl(path, apiUrl);
  const finalConfig = config ?? {};
  return httpClient.get(finalUrl, {
    ...finalConfig
  });
}

export async function axiosPut<D = unknown, R = D>(
  path: string,
  data?: D,
  config?: AxiosRequestConfig | undefined,
  apiUrl?: string
): Promise<AxiosResponse<R>> {
  const finalUrl = await generateFinalUrl(path, apiUrl);
  const finalConfig = config ?? {};
  return httpClient.put(finalUrl, data, {
    ...finalConfig
  });
}

export async function axiosPost<D = unknown, R = D>(
  path: string,
  data?: D,
  config?: AxiosRequestConfig | undefined,
  apiUrl?: string
): Promise<AxiosResponse<R>> {
  const finalUrl = await generateFinalUrl(path, apiUrl);
  const finalConfig = config ?? {};
  return httpClient.post(finalUrl, data, {
    ...finalConfig
  });
}

export async function axiosPostFile<D = unknown, R = D>(
  path: string,
  data?: D,
  config?: AxiosRequestConfig | undefined,
  apiUrl?: string
): Promise<AxiosResponse<R>> {
  const finalUrl = await generateFinalUrl(path, apiUrl);
  const finalConfig = config ?? {};
  return httpClient.post(finalUrl, data, {
    ...finalConfig
  });
}

export async function axiosDelete<R = unknown>(
  path: string,
  config?: AxiosRequestConfig | undefined,
  apiUrl?: string
): Promise<AxiosResponse<R>> {
  const finalUrl = await generateFinalUrl(path, apiUrl);
  const finalConfig = config ?? {};
  return httpClient.delete(finalUrl, {
    ...finalConfig
  });
}
