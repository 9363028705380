import { CardHeader, Skeleton } from '@mui/material';
import { colors } from 'styles/colors';
interface IGroupsSkeleton {
  showSubLine?: number;
}

const GroupsSkeleton = ({ showSubLine = 2 }: IGroupsSkeleton) => {
  return (
    <>
      {[...Array(5)].map((row, rowIndex) => (
        <CardHeader
          sx={{
            borderBottom: `1px solid ${colors.border.primary}`,
            '& .MuiCardHeader-action': {
              margin: 0
            }
          }}
          key={rowIndex}
          avatar={
            <Skeleton
              animation='wave'
              variant='circular'
              width={40}
              height={40}
            />
          }
          action={
            <Skeleton
              animation='wave'
              variant='circular'
              width={20}
              height={20}
            />
          }
          title={
            <Skeleton
              animation='wave'
              height={20}
              width='20%'
              style={{ marginBottom: 6 }}
            />
          }
          subheader={
            <>
              {[...Array(showSubLine)].map((_itm, indexSub) => (
                <Skeleton
                  animation='wave'
                  height={20}
                  width='40%'
                  key={indexSub.toString()}
                />
              ))}
            </>
          }
        />
      ))}
    </>
  );
};

export default GroupsSkeleton;
