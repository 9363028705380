import axios, { AxiosError, Canceler } from 'axios';
import {
  ApiPaths,
  ApiPaths_Chat,
  ApiPaths_Groups,
  ApiPaths_Notification,
  ApiPaths_Post,
  IFriendResponse,
  IGroupHeader,
  ISaveUserEducationDetailsRequest,
  ISaveUserEducationDetailsResponse,
  ISaveUserExperienceDetailsRequest,
  ITopic,
  IUserDetails,
  IUserSummaryRequest
} from 'models/apiPaths';
import {
  IOtpConfirmation,
  IUserRegisterByMobile,
  IUserRegisterResponse
} from 'models/authentication';
import { IGroupeSearchRequest } from 'models/groups';
import {
  ICommentsPost,
  IPostContent,
  IUserPostVoteRequest
} from 'models/myfeed';
import {
  IFriendNotificationAcceptRejectRequest,
  IFriendNotificationResponse,
  INotificationCountResponse,
  INotificationSingleReadRequest,
  ITurnOffNotificationRequest
} from 'models/notification';
import { IReportPostRequest, IReportPostResponse } from 'models/reports';
import {
  BASE_API,
  axiosPost,
  axiosGet,
  axiosPut,
  axiosDelete,
  BASE_API_POST,
  BASE_API_INTERACTION,
  BASE_API_NOTIFICATION,
  BASE_API_CHAT
} from 'utilities/axiosManagerFunctions';

export const getAPIError = (err: any) => {
  /* istanbul ignore next */
  const errorMessage = err.message || err.toString();
  return new AxiosError(
    `API Error: "${errorMessage}"`,
    undefined,
    undefined,
    undefined,
    err?.response
  );
};

export const axiosGroupJoin = async (groupId: any): Promise<any> => {
  try {
    const response = await axiosPut(
      ApiPaths_Groups.GROUP_JOIN + groupId,
      {},
      {},
      BASE_API_POST
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const axiosGroupUnjoin = async (groupId: any): Promise<any> => {
  try {
    const response = await axiosPut(
      ApiPaths_Groups.GROUP_UNJOIN + groupId,
      {},
      {},
      BASE_API_POST
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const axiosPostGroupsList = async (
  payload: any,
  postId: string
): Promise<any> => {
  try {
    const response = await axiosPost(
      `${ApiPaths_Groups.USER_GROUPS_LIST}/${postId}`,
      payload,
      {},
      BASE_API_POST
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostLoginWithEmail = async (
  payload: {
    email: string;
    password: string;
  },
  onInit?: (canceler: Canceler) => void
): Promise<any> => {
  try {
    const response = await axiosPost(
      ApiPaths.LOGIN_EMAIL,
      payload,
      {
        cancelToken: new axios.CancelToken((canceler) => {
          onInit?.(canceler);
        })
      },
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosUserRegisterByEmail = async (
  payload: any,
  onInit?: (canceler: Canceler) => void
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths.SIGNUP_EMAIL,
      payload,
      {
        cancelToken: new axios.CancelToken((canceler) => {
          onInit?.(canceler);
        })
      },
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosUserRegisterByMobile = async (
  payload: IUserRegisterByMobile,
  onInit?: (canceler: Canceler) => void
): Promise<IUserRegisterResponse> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths.SIGNUP_MOBILE,
      payload,
      {
        cancelToken: new axios.CancelToken((canceler) => {
          onInit?.(canceler);
        })
      },
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostOTPVerificationFromLogin = async (
  apiPath: string,
  payload: IOtpConfirmation,
  onInit?: (canceler: Canceler) => void
): Promise<IOtpConfirmation> => {
  try {
    const response = await axiosPost<any>(
      apiPath,
      payload,
      {
        cancelToken: new axios.CancelToken((canceler) => {
          onInit?.(canceler);
        })
      },
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostOTPResend = async (
  apiPath: string,
  payload: IOtpConfirmation,
  onInit?: (canceler: Canceler) => void
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      apiPath,
      payload,
      {
        cancelToken: new axios.CancelToken((canceler) => {
          onInit?.(canceler);
        })
      },
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostForgotRequestEmail = async (
  apiPath: string,
  payload: {
    email: string;
  },
  onInit?: (canceler: Canceler) => void
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      apiPath,
      payload,
      {
        cancelToken: new axios.CancelToken((canceler) => {
          onInit?.(canceler);
        })
      },
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const axiosPostForgotPasswordVerifyOTP = async (
  apiPath: string,
  payload: {
    email: string;
    code: string;
  },
  onInit?: (canceler: Canceler) => void
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      apiPath,
      payload,
      {
        cancelToken: new axios.CancelToken((canceler) => {
          onInit?.(canceler);
        })
      },
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const axiosPostResetPasswordVerify = async (
  apiPath: string,
  payload: {
    email: string;
    code: string;
    newPassword: string;
  },
  onInit?: (canceler: Canceler) => void
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      apiPath,
      payload,
      {
        cancelToken: new axios.CancelToken((canceler) => {
          onInit?.(canceler);
        })
      },
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const axiosPostMyFeed = async (
  apiPath: string,
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(apiPath, payload, {});
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostMyFeedPostId = async (payload: any): Promise<any> => {
  try {
    const ApiPath = ApiPaths.CREATE_POST + '/' + payload.postId;
    const response = await axiosGet<IPostContent>(ApiPath, {});
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosGetVoteList = async (userId: string): Promise<any> => {
  try {
    const ApiPath = ApiPaths.VOTE_USERS + '/' + userId;
    const response = await axiosGet<any>(ApiPath, {}, BASE_API_INTERACTION);
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostForVote = async (
  payload: IUserPostVoteRequest
): Promise<any> => {
  try {
    const response = await axiosPost(
      ApiPaths.POST_VOTE,
      payload,
      {},
      BASE_API_INTERACTION
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostMyGroups = async (
  urlPath: string,
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(urlPath, payload, {});
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosCreatePost = async (
  path: string,
  payload: FormData
): Promise<FormData> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  try {
    const response = await axiosPost<any>(path, payload, config);
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostGroupSearch = async (
  path: string,
  payload: IGroupeSearchRequest
): Promise<any> => {
  try {
    const response = await axiosPost(path, payload, {}, BASE_API_POST);
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const fetchUserDetails = async (
  apiPath: string
): Promise<IUserDetails> => {
  try {
    const response = await axiosGet<IUserDetails>(apiPath, {}, BASE_API);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserProfile = async (
  payload: IUserSummaryRequest
): Promise<ISaveUserEducationDetailsResponse> => {
  try {
    const response = await axiosPut<any>(
      ApiPaths.USER_PROFILE_SUMMARY,
      payload,
      {},
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const saveUserEducationDetails = async (
  apiPath: string,
  payload: ISaveUserEducationDetailsRequest
): Promise<any> => {
  try {
    const response = await axiosPut<ISaveUserEducationDetailsRequest>(
      apiPath,
      payload,
      {},
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const deleteUserEducationDetails = async (
  apiPath: string,
  payload: ISaveUserEducationDetailsRequest
): Promise<any> => {
  try {
    const response = await axiosDelete(
      apiPath,
      {
        data: payload
      },
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const saveUserExperienceDetails = async (
  apiPath: string,
  payload: ISaveUserExperienceDetailsRequest
): Promise<any> => {
  try {
    const response = await axiosPut<ISaveUserExperienceDetailsRequest>(
      apiPath,
      payload,
      {},
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const deleteUserExperienceDetails = async (
  apiPath: string,
  payload: ISaveUserExperienceDetailsRequest
): Promise<any> => {
  try {
    const response = await axiosDelete(
      apiPath,
      {
        data: payload
      },
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const fetchInterestTopics = async (): Promise<ITopic[]> => {
  try {
    const response = await axiosGet<ITopic[]>(
      ApiPaths.METADATA_CONTROLLER,
      {},
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status !== 200) {
      throw getAPIError(error);
    }
    throw new Error(error.message || 'An error occurred while fetching topics');
  }
};

export const updateInterestTopics = async (
  selectedIDs: string[]
): Promise<any> => {
  try {
    const response = await axiosPut(
      ApiPaths.UPDATE_USER_INTERESTS,
      {
        id: selectedIDs
      },
      {},
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const updateBio = async (bio: string): Promise<any> => {
  try {
    const response = await axiosPut(
      ApiPaths.USER_BIO_UPDATE,
      {
        userBio: bio
      },
      {},
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const axiosGetGroupPost = async (
  payload: string,
  path: string
): Promise<any> => {
  try {
    const response = await axiosGet<any>(path + payload, {}, BASE_API_POST);
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export async function fetchFriends(): Promise<IFriendResponse[]> {
  try {
    const response = await axiosGet<IFriendResponse[]>(
      ApiPaths.FRIEND,
      {},
      BASE_API
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch friends list');
  }
}

export const axiosGetFriendRequestList = async (): Promise<
  IFriendNotificationResponse[]
> => {
  try {
    const response = await axiosGet<IFriendNotificationResponse[]>(
      ApiPaths.FRIEND_REQUEST,
      {},
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostFriendRequestList = async (
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths.FRIEND_PAGE_RESPONSE,
      payload,
      {},
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPutFriendRequest = async (
  payload: IFriendNotificationAcceptRejectRequest
): Promise<any> => {
  try {
    const response = await axiosPut(
      ApiPaths.FRIEND_RESPONSE,
      payload,
      {},
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const postFriendRequest = async (
  payload: any,
  apiPath: string
): Promise<any> => {
  try {
    const response = await axiosPost(apiPath, payload, {}, BASE_API);
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const postNotificationGeneralList = async (
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths_Notification.NOTIFICATION_LIST_PAGE,
      payload,
      {},
      BASE_API_NOTIFICATION
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const postNotificationReadAll = async (): Promise<any> => {
  try {
    const response = await axiosPost(
      ApiPaths_Notification.NOTIFICATION_READ_ALL,
      {},
      {},
      BASE_API_NOTIFICATION
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const postNotificationRead = async (
  payload: INotificationSingleReadRequest
): Promise<any> => {
  try {
    const response = await axiosPost(
      ApiPaths_Notification.NOTIFICATION_READ,
      payload,
      {},
      BASE_API_NOTIFICATION
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const deleteNotification = async (
  notificationID: string
): Promise<any> => {
  try {
    const response = await axiosDelete(
      `${ApiPaths_Notification.NOTIFICATION_DELETE}/${notificationID}`,
      {},
      BASE_API_NOTIFICATION
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const putTurnOffNotification = async (
  payload: ITurnOffNotificationRequest
): Promise<any> => {
  try {
    const response = await axiosPut<any>(
      ApiPaths_Notification.NOTIFICATION_OFF,
      payload,
      {},
      BASE_API_NOTIFICATION
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const axiosPostBlockUnblock = async (
  apiPath: string,
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost(apiPath, payload, {}, BASE_API);
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const sendPostReport = async (
  payload: IReportPostRequest
): Promise<IReportPostResponse> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths_Post.REPORT_POST,
      payload,
      {},
      BASE_API_POST
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosRePost = async (payload: any): Promise<any> => {
  try {
    const response = await axiosPost(
      ApiPaths_Post.REPOST_POST,
      payload,
      {},
      BASE_API_POST
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosDeletePost = async (payload: any): Promise<any> => {
  try {
    const response = await axiosDelete(ApiPaths.POST_REMOVE, {
      data: payload
    });
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const axiosPostUserSearch = async (payload: {
  keyword: string;
}): Promise<any> => {
  try {
    const response = await axiosPost(
      ApiPaths.USER_SEARCH,
      payload,
      {},
      BASE_API
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const axiosPostUserDeleteRequest = async (
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost(
      ApiPaths.USER_DELETE_REQUEST,
      payload,
      {},
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostCommentsPostId = async (
  payload: any,
  postId: string
): Promise<any> => {
  try {
    const response = await axiosPost(
      `${ApiPaths.COMMENTS_POST_ID_PAGINATION}/${postId}`,
      payload,
      {},
      BASE_API_INTERACTION
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPostComment = async (
  payload: ICommentsPost
): Promise<any[]> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths.COMMENTS,
      payload,
      {},
      BASE_API_INTERACTION
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosUserImage = async (payload: FormData): Promise<FormData> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  try {
    const response = await axiosPut<any>(
      ApiPaths.USER_MEDIA,
      payload,
      config,
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPutBannerImage = async (
  payload: FormData
): Promise<FormData> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  try {
    const response = await axiosPut<any>(
      ApiPaths.USER_BANNER_MEDIA,
      payload,
      config,
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const axiosPutGroupImage = async (payload: FormData): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  try {
    const response = await axiosPut(
      ApiPaths_Groups.GROUP_IMAGE_MEDIA,
      payload,
      config,
      BASE_API_POST
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const getActiveUser = async (): Promise<any> => {
  try {
    const response = await axiosGet<any>(
      ApiPaths.GET_ACTIVE_USER,
      {},
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const getNotificationCount =
  async (): Promise<INotificationCountResponse> => {
    try {
      const response = await axiosGet<INotificationCountResponse>(
        ApiPaths_Notification.NOTIFICATION_COUNT,
        {},
        BASE_API_NOTIFICATION
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
export const axiosGroupProfile = async (
  payload: IGroupHeader
): Promise<any> => {
  try {
    const response = await axiosPut(
      ApiPaths_Groups.GROUP_PROFILE,
      payload,
      {},
      BASE_API_POST
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};
export const axiosGroupPutBannerImage = async (
  payload: FormData
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  try {
    const response = await axiosPut(
      ApiPaths_Groups.Group_BANNER_MEDIA,
      payload,
      config,
      BASE_API_POST
    );
    return response.data;
  } catch (error: any) {
    throw getAPIError(error);
  }
};

export const axiosPostFriendsList = async (
  payload: any,
  postId: string
): Promise<any> => {
  try {
    const response = await axiosPost(
      `${ApiPaths.USER_PROFILE_FRIENDS_LIST}/${postId}`,
      payload,
      {},
      BASE_API
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const getCarousel = async (): Promise<any> => {
  try {
    const response = await axiosGet<any>(ApiPaths.USER_CAROUSEL, {}, BASE_API);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status !== 200) {
      throw getAPIError(error);
    }
    throw new Error(error.message || 'An error occurred while fetching topics');
  }
};

export const getFeatureFlag = async (): Promise<any> => {
  try {
    const response = await axiosGet<any>(ApiPaths.FEATURE_FLAG, {}, BASE_API);
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const createOrUpdateUserStatusActiveStatus = async (  
  payload?: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      `${ApiPaths_Chat.UPDATE_USER_STATUS}`,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const getAllConversation = async (): Promise<any> => {
  try {
    const response = await axiosGet<any>(
      ApiPaths_Chat.GET_ALL_CONVERSATIONS,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const getAllMessages = async (
  conversationId: string
): Promise<any> => {  
  try {
    const response = await axiosGet<any>(
      `${ApiPaths_Chat.GET_ALL_MESSAGES}/${conversationId}`,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const createConversation = async (
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths_Chat.CREATE_CONVERSATION,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const sendMessage = async (
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths_Chat.SEND_MESSAGE,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const updateMessageReaction = async (
  payload: any
): Promise<any[]> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths_Chat.REACT_TO_MESSAGE,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const deleteMessage = async (
  conversationId: string,
  messageId: string
): Promise<any> => {
  try {
    const response = await axiosDelete<any>(
      `${ApiPaths_Chat.DELETE_MESSAGE}/${conversationId}/messages/${messageId}`,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const deleteConversation = async (
  conversationId: string
): Promise<any> => {
  try {
    const response = await axiosDelete(
      `${ApiPaths_Chat.DELETE_CONVERSATION}/${conversationId}`,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const addParticipantToConversation = async (
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths_Chat.ADD_PARTICIPANT,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const updateDeliveredStatus = async (
  payload?: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      `${ApiPaths_Chat.UPDATE_DELIVERED_STATUS}`,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const updateReadStatus = async (
  conversationId: string,
  payload?: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      `${ApiPaths_Chat.UPDATE_READ_STATUS}/${conversationId}`,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const signalrNegotiate = async (
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      `${ApiPaths_Chat.SIGNALR_NEGOTIATE}?userId=${payload}`,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const signalrConnect = async (
  payload: { userId: string; connectionId: string }
): Promise<any> => {
  try {
    const { userId, connectionId } = payload;
    const response = await axiosPost<any>(
      `${ApiPaths_Chat.SIGNALR_CONNECT}?userId=${userId}&connectionId=${connectionId}`,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const getAllGroupConversation = async (): Promise<any> => {
  try {
    const response = await axiosGet<any>(
      ApiPaths_Chat.GET_ALL_GROUP_CONVERSATIONS,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const getGroupMessages = async (
  groupConversationId: string
): Promise<any> => {  
  try {
    const response = await axiosGet<any>(
      `${ApiPaths_Chat.GET_GROUP_MESSAGES}/${groupConversationId}`,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const createGroupConversation = async (
  groupId: string,
  payload?: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      `${ApiPaths_Chat.CREATE_GROUP_CONVERSATION}/${groupId}`,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const sendMessageToGroup = async (
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths_Chat.SEND_GROUP_MESSAGE,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const updateGroupMessageDeliveredStatus = async (
  payload?: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      `${ApiPaths_Chat.UPDATE_GROUP_MESSAGE_DELIVERED_STATUS}`,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const updateGroupMessageReadStatus = async (
  groupConversationId: string,
  payload?: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      `${ApiPaths_Chat.UPDATE_GROUP_MESSAGE_READ_STATUS}/${groupConversationId}`,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const updateReactionToGroupMessage = async (
  payload: any
): Promise<any[]> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths_Chat.REACT_TO_GROUP_MESSAGE,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const deleteMessageFromGroup = async (
  groupConversationId: string,
  messageId: string
): Promise<any> => {
  try {
    const response = await axiosDelete<any>(
      `${ApiPaths_Chat.DELETE_GROUP_MESSAGE}/${groupConversationId}/messages/${messageId}`,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const deleteGroupConversation = async (
  groupConversationId: string
): Promise<any> => {
  try {
    const response = await axiosDelete(
      `${ApiPaths_Chat.DELETE_GROUP_CONVERSATION}/${groupConversationId}`,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};

export const reportConversation = async (
  payload: any
): Promise<any> => {
  try {
    const response = await axiosPost<any>(
      ApiPaths_Chat.REPORT_CONVERSATION,
      payload,
      {},
      BASE_API_CHAT
    );
    return response.data;
  } catch (err: any) {
    throw getAPIError(err);
  }
};