import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, IconButton, TextField, Button, Stack, Chip } from '@mui/material';
import { AddOutlinedLight, BackArrowIcon, UserOutlinedLight } from 'assets/icon';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import { colors } from 'styles/colors';
import { CloseRoundIcon, GreenTick } from 'assets';
import AddParticipants from './addParticipants';
import { IFriends } from 'models/userProfile';
import { IMessageRequest, ISendMessageRequest } from './chatPopup';
import { useAppSelector } from 'store/hook';
import { RootState } from 'store';
import { sendMessage } from 'services/api';

interface CreateMessagePopupProps {
  open: boolean;
  onClose: () => void;
  onMessageSent?: (conversationId: string) => void;
}

const CreateMessagePopup: React.FC<CreateMessagePopupProps> = ({ open, onClose, onMessageSent }) => {
  const [message, setMessage] = useState('');
  const [isSelectRecipientScreen, setIsSelectRecipientScreen] = useState(false);
  const [selectedFriends, setSelectedFriends] = useState<any[]>([]);
  const { userProfile }: any = useAppSelector(
    (state: RootState) => state.userAuth
  );

  const handleAddRecipient = () => {
    setIsSelectRecipientScreen(true);
  };

  const handleOnClose = (selectedUsers: IFriends[]) => {
    setSelectedFriends(selectedUsers)
    setIsSelectRecipientScreen(false);
  };

  const handleSend = () => {
    const inputMessage: IMessageRequest = {
      content: message,
      delivered: [],
      read: [],
      sent: true,
      participantIds: selectedFriends.map(friend => friend.user.id),
      senderId: userProfile.id,
      createdAt: new Date()
    }

    const messageRequest: ISendMessageRequest = {
      conversationId: null,
      message: inputMessage
    }

    sendMessage(messageRequest).then((res) => {
      if (onMessageSent) {
        onMessageSent(res.conversationId); 
      }      
    }).catch((err) => {
      console.error("Error Sending Message:", err);
    })
      .finally(() => {
        setMessage('');
        setSelectedFriends([]);
        onClose();
      });
  };

  const handleRemoveParticipant = (id: string) => {
    setSelectedFriends((prevFriends) =>
      prevFriends.filter((friend) => friend.user.id !== id)
    );
  };

  const getSelectedMembersChips = () => {
    return <Stack direction="row" spacing={1}>
      {selectedFriends.map((friend, index) => {
        return <Chip key={index} color='primary' label={friend.user.summary.userName.split(' ')[0]} onDelete={() => handleRemoveParticipant(friend.user.id)} />
      })}
    </Stack>
  }

  useEffect(() => {
    setSelectedFriends([]);
  }, [])

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '45%',
          height: '60%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: 2,
          overflow: 'auto'
        }}
      >
        {!isSelectRecipientScreen && <>
          <Box sx={{
            display: "flex",
            justifyContent: 'space-between',
          }}>
            <Box sx={{
              display: "flex",
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
              <IconButton onClick={onClose}>
                <BackArrowIcon />
              </IconButton>
              <Typography variant="h6">Create Message</Typography>
            </Box>
            <Box>
              <IconButton onClick={onClose}>
                <CloseRoundIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#f6f6f6',
              padding: 1,
              borderRadius: 1
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', overflowX: 'scroll' }}>
              <IconButton edge="end">
                <UserOutlinedLight />
              </IconButton>
              <TypographyWithI18N
                className="bodyTextBold"
                i18nKey="To: "
                sx={{ color: colors.text.darkGray, marginLeft: 1, whiteSpace: 'nowrap' }}
              />
              <Box sx={{ ml: 2 }}>
                {getSelectedMembersChips()}
              </Box>
            </Box>
            <IconButton edge="end" onClick={handleAddRecipient}>
              <AddOutlinedLight />
            </IconButton>
          </Box>
          <Box mt={2} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <TextField
              fullWidth
              placeholder="Write your message..."
              maxRows={15}
              variant="outlined"
              multiline
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{
                flexGrow: 1,
                backgroundColor: '#f6f6f6',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            />
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
            <Button variant="contained" disabled={selectedFriends.length === 0 || message.trimEnd() === ''} color="primary" sx={{ mr: 2 }} onClick={handleSend}>
              Send
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </>}
        {isSelectRecipientScreen &&
          <AddParticipants onClose={handleOnClose} existingParticipantIds={[]} selectedFriends={selectedFriends} />
        }
      </Box>
    </Modal>
  );
};

export default CreateMessagePopup;