import { Box, CardHeader, CircularProgress } from '@mui/material';
import GroupsSkeleton from '__mocks__/skeletonLoading/groupsLoader';
import { CheckGreenCircle, CloseRoundIcon } from 'assets';
import { CloseRedCircleIcon } from 'assets/icon';
import { FriendRequestStatus } from 'models/enum';
import {
  IFriendNotificationAcceptRejectRequest,
  IFriendNotificationResponse,
  IFriendNotificationUser
} from 'models/notification';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import AvatarImg from 'pages/components/avatarImg';
import AdminButton from 'pages/components/button';
import ConfirmationModal from 'pages/components/confirmationModal';
import AdminIconButton from 'pages/components/iconButton';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  axiosGetFriendRequestList,
  axiosPostFriendRequestList,
  axiosPutFriendRequest
} from 'services/api';
import { setFriendData } from 'store/friendSlice';
import { colors } from 'styles/colors';
import { displayDurationInHrsAndMins } from 'utilities/formatDuration';
import { getUrl } from 'utilities/userImageURL';

interface IFriendRequest {
  limit?: number;
  showLess?: (action: boolean) => void;
  btnShow?: boolean;
}

interface IOpenDialog {
  open: boolean;
  message?: string;
  label?: string;
  userId?: string;
  username?: string;
  loaderBtn?: boolean;
  confirmTxt?: boolean;
  icon?: JSX.Element;
}

const FriendRequest = ({ limit, showLess, btnShow }: IFriendRequest) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [friendRequestList, setFriendRequestList] = useState<
    IFriendNotificationResponse[]
  >([]);
  const [confirmModal, setConfirmModal] = React.useState<IOpenDialog>();
  // const [pageChange, setPageChange] = useState({
  //   previousPageCursor: '',
  //   nextPageCursor: '',
  //   nextPage: true
  // });
  const [loader, setLoader] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const fetchData = async () => {
    try {
      // const payload = {
      //   size: 10,
      //   nextPageCursor: pageChange?.nextPageCursor ?? ''
      // };
      // axiosPostFriendRequestList(payload)
      const res = await axiosGetFriendRequestList();
      if (res) {
        // setPageChange({
        //   previousPageCursor: res.previousPageCursor,
        //   nextPageCursor: res.nextPageCursor,
        //   nextPage: res.nextPageCursor ? true : false
        // });
        const friendRes = limit ? res.slice(limit) : res;
        showLess && showLess(res.length > 1 ? true : false);
        setFriendRequestList(friendRes);
      }
    } catch (error: unknown) {
      setFriendRequestList([]);
    } finally {
      setShowLoader(false);
      setLoader('');
    }
  };

  useEffect(() => {
    setShowLoader(true);
    fetchData();
  }, []);

  const renderAcceptReject = async (
    statusDecision: string,
    userDetails: IFriendNotificationUser
  ) => {
    setLoader(userDetails.username);
    const acceptPayload: IFriendNotificationAcceptRejectRequest = {
      initiatingUserId: userDetails?.id,
      accept: statusDecision === FriendRequestStatus.ACCEPTED ? true : false
    };
    const response = await axiosPutFriendRequest(acceptPayload);
    if (!response?.error) {
      fetchData();
      dispatch(
        setFriendData({
          isFriendData: response
        })
      );
      setConfirmModal((pv) => ({
        ...pv,
        open: true,
        loaderBtn: false,
        message: `You have ${
          statusDecision === FriendRequestStatus.ACCEPTED
            ? FriendRequestStatus.ACCEPTED
            : FriendRequestStatus.REJECTED
        } the friend request from ${userDetails.username}`,
        icon:
          statusDecision === FriendRequestStatus.ACCEPTED ? (
            <CheckGreenCircle />
          ) : (
            <CloseRedCircleIcon />
          )
      }));
    }
  };

  return (
    <>
      {showLoader && friendRequestList.length === 0 ? (
        <GroupsSkeleton />
      ) : (
        friendRequestList && (
          <>
            {/* <InfiniteScroll
              dataLength={friendRequestList.length}
              next={fetchData}
              hasMore={pageChange?.nextPage}
              loader={<GroupsSkeleton />}
              scrollableTarget='scrollToElement'
            > */}
            {friendRequestList.map((_item: any, indexVote: number) => {
              return (
                <CardHeader
                  key={indexVote.toString()}
                  sx={{
                    borderBottom: `1px solid ${colors.border.primary}`,
                    alignItems: 'center',
                    flexDirection: { xs: 'column', lg: 'row' },
                    '& .MuiCardHeader-action': {
                      xs: {
                        alignSelf: 'center',
                        marginTop: 0
                      },
                      lg: {
                        alignSelf: 'flex-start',
                        marginTop: -4
                      }
                    }
                  }}
                  avatar={
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/profile/${_item.user.id}`)}
                    >
                      <AvatarImg
                        id='userImg'
                        enableLetterImg={_item?.user?.imageId ? false : true}
                        alt={_item?.user?.username}
                        src={getUrl(_item?.user?.imageId || '')}
                        name={_item?.user?.username}
                        nameStyle={{
                          fontSize: 14
                        }}
                        width={40}
                        height={40}
                        style={{
                          objectFit: 'cover',
                          borderRadius: '50%'
                        }}
                      />
                    </Box>
                  }
                  action={
                    <Box sx={{ position: 'relative' }}>
                      {loader === _item?.user?.username ? (
                        <CircularProgress
                          size={16}
                          sx={{
                            position: 'absolute',
                            right: 35,
                            top: 15
                          }}
                        />
                      ) : (
                        <>
                          {!btnShow && (
                            <>
                              <AdminButton
                                id='Accept'
                                aria-label='Accept'
                                title={'Accept'}
                                onClick={() =>
                                  renderAcceptReject(
                                    FriendRequestStatus.ACCEPTED,
                                    _item?.user
                                  )
                                }
                                variant='outlined'
                                size='small'
                                className='bodyTextSmall'
                                sxProps={{ width: 90, height: 24, padding: 0 }}
                              />
                              <AdminIconButton
                                onClick={() =>
                                  renderAcceptReject(
                                    FriendRequestStatus.REJECTED,
                                    _item?.user
                                  )
                                }
                                id='settings'
                                aria-label='more'
                                icon={
                                  <CloseRoundIcon
                                    style={{ width: 24, height: 24 }}
                                  />
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  }
                  title={
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/profile/${_item.user.id}`)}
                    >
                      <TypographyWithI18N
                        className='smallHeaderBold'
                        i18nKey={_item?.user?.username}
                        sx={{
                          color: colors.text.primaryBlack
                          // maxWidth:'70%'
                        }}
                      />
                      {_item?.user?.summary?.designation && (
                        <TypographyWithI18N
                          className='metadata'
                          i18nKey={_item?.user?.summary?.designation}
                          // maxWidth={'70%'}
                        />
                      )}
                      <TypographyWithI18N
                        className='metadata'
                        i18nKey={
                          displayDurationInHrsAndMins(_item?.createdAt)
                            ?.timeLabel
                        }
                      />
                    </Box>
                  }
                />
              );
            })}
            {/* </InfiniteScroll> */}
            {!showLoader && friendRequestList.length === 0 && (
              <TypographyWithI18N
                className='smallHeader'
                py={3}
                textAlign={'center'}
                i18nKey={'No friend request available currently'}
              />
            )}
            {confirmModal?.open && (
              <ConfirmationModal
                open={confirmModal.open}
                message={confirmModal.message}
                confirmTxt={false}
                icon={confirmModal.icon}
                onExit={() =>
                  setConfirmModal((pv) => ({
                    ...pv,
                    open: false
                  }))
                }
              />
            )}
          </>
        )
      )}
    </>
  );
};

export default FriendRequest;
