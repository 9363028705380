import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { OutlinedInput, SxProps } from '@mui/material';
import { colors } from 'styles/colors';
import { KeyboardArrowDown } from '@mui/icons-material';

export interface ISelectDropdownProps {
  options: any;
  value: any;
  onChangeEvent: (event: SelectChangeEvent) => void;
  selectSx: SxProps;
}

const SelectDropdown = ({
  options,
  value,
  onChangeEvent,
  selectSx
}: ISelectDropdownProps) => {
  return (
    <FormControl size='medium' fullWidth>
      <Select
        labelId='select-small-label'
        id='select-small'
        data-testid='select-small'
        input={<OutlinedInput />}
        value={value}
        onChange={onChangeEvent}
        displayEmpty
        renderValue={() => {
          if (!value) {
            return 'Select Reason';
          }
          return value;
        }}
        IconComponent={(props) => <KeyboardArrowDown {...props} />}
        sx={{
          color: colors.text.primaryTheme,
          fontSize: 15,
          ...selectSx
        }}
      >
        {options.map((_itm: any, index: number) => (
          <MenuItem value={_itm} key={index.toString()}>
            {_itm}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDropdown;
