import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isChatEnabled: false
};

const featureFlagSlice = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    setFeatureFlag: (state, action) => {
      state.isChatEnabled = action.payload.isChatEnabled;
    }
  }
});

export const { setFeatureFlag } = featureFlagSlice.actions;

export default featureFlagSlice.reducer;
