import { IconButton, IconButtonProps } from '@mui/material';
import React from 'react';

export type AdminIconButtonProps = IconButtonProps & {
  id?: string;
  icon: React.ReactNode;
};

const AdminIconButton: React.FC<AdminIconButtonProps> = ({
  id = 'iconButton-contain',
  icon,
  ...props
}) => {
  return (
    <IconButton id={id} data-testid={id} {...props}>
      {icon}
    </IconButton>
  );
};

export default AdminIconButton;
