import { AvatarGroup, Badge, Box, ClickAwayListener, Icon, IconButton, InputAdornment, List, ListItem, ListItemButton, MenuItem, MenuList, Paper, TextField } from '@mui/material';
import TypographyWithI18N from 'pages/components/TypographyWithI18N';
import { useEffect, useState } from 'react'
import { colors } from 'styles/colors';
import { axiosPostFriendsList, axiosPostGroupSearch, createConversation, fetchUserDetails, getAllConversation, updateDeliveredStatus } from 'services/api';
import { ApiPaths, ApiPaths_Groups, IUserDetails } from 'models/apiPaths';
import AvatarImg from 'pages/components/avatarImg';
import { getUrl, groupImgUrl } from 'utilities/userImageURL';
import { CloseRoundIcon, SearchIcon } from 'assets';
import { IFriends, IFriendsList } from 'models/userProfile';
import GroupsSkeleton from '__mocks__/skeletonLoading/groupsLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IUserGroupSearch } from 'models/groups';
import ChatPopup, { IMessage } from './chatPopup';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppSelector } from 'store/hook';
import { NoConversations } from 'assets/icon';

interface IFriendItemProps {
  loggedUser: any;
  friends: any[];
  conversation: any;
  onClick: (conversationId: any, friends: any) => void;
}

const FriendItem = ({ loggedUser, friends, conversation, onClick }: IFriendItemProps) => {

  const getDisplayName = (friends: any[]) => {
    if (friends.length === 1) return friends[0].username;
    if (friends.length === 2) return `${friends[0].username}, ${friends[1].username}`;
    if (friends.length > 2) {
      return `${friends[0].username}, ${friends[1].username}, +${friends.length - 2}`;
    }
    return '';
  };

  const renderAvatar = (friend: any, index: number) => (
    <AvatarImg
      key={index}
      id="userImg"
      enableLetterImg={!friend.imageId}
      alt={friend.username}
      src={getUrl(friend.imageId)}
      name={friend.username}
      width={30}
      height={30}
      style={{
        objectFit: 'cover',
        borderRadius: '50%',
        border: `2px solid ${colors.border.primary}`,
        marginLeft: index > 0 ? -30 : 0,
      }}
    />
  );

  const getAvatars = (friends: any[]) => {
    if (friends.length === 1) {
      return friends.slice(0, 4).map((friend, index) => (
        <Badge
          key={index}
          sx={{
            '& .MuiBadge-dot': {
              borderRadius: '50%',
              width: friend.onlineStatus ? '15px' : 0,
              height: friend.onlineStatus ? '15px' : 0,
              border: friend.onlineStatus ? `2px solid ${colors.border.primary}` : '',
              backgroundColor: '#4caf50',
            },
          }}
          variant="dot"
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {renderAvatar(friend, index)}
        </Badge>
      ));
    } else {
      return (
        <AvatarGroup
          max={2}
          spacing={30}
          sx={{
            '& .MuiAvatarGroup-avatar': {
              width: 30,
              height: 30,
              fontSize: 16,
              backgroundColor: colors.text.primaryTheme,
            },
          }}
        >
          {friends.map((friend, index) => renderAvatar(friend, index))}
        </AvatarGroup>
      );
    }
  };

  const formatTimeDifference = (): string => {
    const updatedDate = new Date(conversation.lastMessage.updatedAt || conversation.modifiedAt);
    const currentDate = new Date();

    const isToday = updatedDate.toDateString() === currentDate.toDateString();

    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    const isYesterday = updatedDate.toDateString() === yesterday.toDateString();

    if (isToday) {
      return updatedDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } else if (isYesterday) {
      return "Yesterday";
    } else {
      return updatedDate.toLocaleDateString(undefined, {
        day: "2-digit",
        month: "2-digit",
      });
    }
  };

  const getLastMessage = () => {
    if (conversation.lastMessage.senderId != null && conversation.lastMessage.content != null) {
      if (conversation.lastMessage.senderId === loggedUser?.id) {
        return ("You: " + conversation.lastMessage.content);
      }
      else {
        const sender = friends.find(x => x.userId === conversation.lastMessage.senderId);
        const firstName = sender.username.split(" ")[0];
        return (`${firstName}: ` + conversation.lastMessage.content);
      }
    }
    return '';
  }

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => onClick(conversation.conversationId, friends)}
      >
        <Box display='flex' justifyContent='start' sx={{ width: '30px' }}>
          {getAvatars(friends)}
        </Box>
        <Box display='flex' flexDirection='column' sx={{
          overflow: 'hidden',
        }}>
          <TypographyWithI18N
            className={conversation.unreadCount !== 0 ? 'bodyTextSmallBold' : 'bodyTextSmall'}
            i18nKey={getDisplayName(friends)}
            sx={{
              marginLeft: 2,
              color: colors.text.primaryBlack,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: 200,
              textOverflow: 'ellipsis',
            }} />
          <TypographyWithI18N
            i18nKey={getLastMessage()}
            fontSize={12}
            sx={{
              marginLeft: 2,
              color: colors.text.approxSuvaGrey,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: 200,
              textOverflow: 'ellipsis',
            }} />
        </Box>
      </ListItemButton>
      <Box display='flex' flexDirection='column' sx={{ marginRight: 2, alignItems: 'flex-end' }}>
        <TypographyWithI18N
          i18nKey={formatTimeDifference()}
          fontSize={12}
          sx={{
            color: colors.text.approxSuvaGrey
          }} />
        {conversation.unreadCount !== 0 && <Box
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            borderRadius: "15px",
            height: "25px",
            width: "25px",
            fontSize: 14,
            fontWeight: "bold",
            boxShadow: 1,
          }}>
          {conversation.unreadCount}
        </Box>}
      </Box>
    </ListItem>
  );
};

export interface IOpenDialog {
  open: boolean;
  id?: string;
  loaderBtn?: boolean;
  successModal?: boolean;
}

const ChatsScreen = () => {
  const [showAll, setShowAll] = useState(false);
  const [friendData, setFriendData] = useState<any[]>([]);
  const [conversationsData, setConversationsData] = useState<any[]>([]);
  const conversationsToShow = showAll ? conversationsData : conversationsData.slice(0, 5);
  const [loggedUser, setLoggedUser] = useState<IUserDetails>();
  const [loader, setLoader] = useState<boolean>(false);
  const [scroll, setScroll] = useState<boolean>(true);
  const [activeConversations, setActiveConversations] = useState<{ conversationId: string; friends: IFriendsList[] }[]>([]);
  const navigate = useNavigate();
  const [pageChange, setPageChange] = useState({
    previousPageCursor: '',
    nextPageCursor: '',
    nextPage: true
  });

  const signalr = useAppSelector((state: RootState) => state.signalr);

  useEffect(() => {
    if (friendData != null) {
      setFriendData((prevData) => {
        return prevData.map((friend) => {
          if (friend.userId === signalr.userStatus.userid) {
            return {
              ...friend,
              onlineStatus: signalr.userStatus.isonline
            };
          }
          return friend;
        });
      });
    }
  }, [signalr.userStatus]);

  const handleCloseChat = (conversationId: string) => {
    setActiveConversations(activeConversations.filter((activeConversation) => activeConversation.conversationId !== conversationId));
    getConversationsList();
  };

  const handleConversationClick = (conversationId: string, friends: any) => {
    if (!activeConversations.find((activeConversation) => activeConversation.conversationId === conversationId)) {
      if (activeConversations.length >= 3) {
        setActiveConversations(prevConversations => [
          ...prevConversations.slice(1),
          { conversationId, friends },
        ]);
      } else {
        setActiveConversations([
          ...activeConversations,
          { conversationId, friends }
        ]);
      }
    }
  };

  const handleNewConversation = async (loggedUserId: any, friend: IFriendsList) => {
    const newConversation = await createConversation([loggedUserId, friend.user.id]);
    const friends = newConversation.participants;
    handleConversationClick(newConversation.conversation.id, friends);
  }

  const onScrollEvent = (e: any) => {
    setScroll(!scroll);
  };

  const userProfileDetails = async () => {
    const userProfile = await fetchUserDetails(ApiPaths.ME);
    setLoggedUser(userProfile);
    getConversationsList();
  };

  const handleIncomingMessage = (newMessage: any) => {
    if (newMessage != null || newMessage.length === 0) {
      const conversationId = newMessage.conversationId;
      const message = newMessage.message;

      setConversationsData((prevConversations) => {
        const updatedConversations = prevConversations.map((conversation) =>
          conversation.conversationId === conversationId
            ? {
              ...conversation,
              lastMessage: {
                content: message.content,
                senderId: message.senderId,
                updatedAt: message.createdAt
              },
              modifiedAt: message.createdAt,
              unreadCount: (conversation.unreadCount || 0) + 1,
            }
            : conversation
        );

        const conversationExists = prevConversations.some(
          (conversation) => conversation.conversationId === conversationId
        );

        const newConversations = conversationExists
          ? updatedConversations
          : [
            ...updatedConversations,
            {
              conversationId: conversationId,
              lastMessage: message,
              deletedBy: [],
              modifiedAt: message.createdAt,
              participantIds: [message.senderId, message.receiverId],
              unreadCount: 1,
            },
          ];

        return newConversations.sort(
          (a, b) => new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime()
        );
      });
      updateDeliveredStatus();
    }
  };

  useEffect(() => {
    if (conversationsData.length > 0) {
      handleIncomingMessage(signalr.messages);
    }
  }, [signalr.messages]);

  const getConversationsList = () => {
    setLoader(true);
    getAllConversation()
      .then((data) => {
        const conversations = data.conversationHistory.conversations;
        const participantsData = data.participants;
        setFriendData(participantsData);
        if (conversations != null) {
          setConversationsData(conversations);
        }
      })
      .catch((err) => {
        console.error("Error fetching conversations:", err);
      })
      .finally(() => {
        updateDeliveredStatus();
        setLoader(false);
      });
  };

  useEffect(() => {
    userProfileDetails();
  }, []);


  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 2,
          borderBottom: `1px solid ${colors.border.primary}`
        }}
      >
        <TypographyWithI18N
          className='smallHeaderBold'
          i18nKey={'Recent Chats'}
          sx={{
            color: colors.text.primaryBlack,
            whiteSpace: 'nowrap'
          }}
        />
      </Box>
      <Box sx={{
        width: '100%',
        height: 300, // Comment this line, so the chat screen will be expandable based on content.
        overflowY: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 1,
        '&::-webkit-scrollbar': { // To hide scrollbar.
          display: 'none',
        },
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
      }}>
        {loader ? (
          <GroupsSkeleton showSubLine={1} />
        ) : conversationsToShow?.length > 0 ? (
          <InfiniteScroll
            dataLength={conversationsToShow?.length}
            next={() => { }}
            hasMore={pageChange?.nextPage}
            loader={loader && <GroupsSkeleton showSubLine={1} />}
            scrollableTarget='scrollToElement'
            onScroll={onScrollEvent}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16
            }}
          >
            <Box sx={{
              width: '100%',
              height: 300, // Comment this line, so the chat screen will be expandable based on content.
              overflowY: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 1,
              '&::-webkit-scrollbar': { // To hide scrollbar.
                display: 'none',
              },
              '-ms-overflow-style': 'none',
              'scrollbar-width': 'none',
            }}>
              <List>
                {conversationsToShow.map((conversation, index) => {

                  const otherParticipantIds = conversation.participantIds.filter((id: string) => id !== loggedUser?.id);
                  const friend = friendData.filter(friend => otherParticipantIds.includes(friend.userId));

                  return <FriendItem key={index} loggedUser={loggedUser} friends={friend} conversation={conversation} onClick={handleConversationClick} />
                })}
              </List>
              {conversationsToShow.length > 3 && <TypographyWithI18N
                i18nKey={showAll ? 'Show Less' : 'Show More'}
                variant="body2"
                component="a"
                onClick={() => setShowAll(!showAll)}
                sx={{
                  color: colors.linkBlue,
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'none',
                  },
                }}
              />}
            </Box>
          </InfiniteScroll>
        ) : (
          <Box sx={{ margin: 'auto' }} display='flex' flexDirection='column' alignItems='center'>
            <>
              <NoConversations style={{ width: '50%', height: '50%' }} />
              <Box>
                <TypographyWithI18N
                  textAlign={'center'}
                  fontSize={14}
                  color={colors.text.primaryTheme}
                  i18nKey={'Every great Conversation starts somewhere'}
                />
                <TypographyWithI18N
                  textAlign={'center'}
                  fontSize={12}
                  i18nKey={'why not start yours now?'}
                />
              </Box>
            </>
          </Box>
        )}
        {activeConversations.map((activeConversation, index) => (
          <ChatPopup
            key={activeConversation.conversationId}
            conversationId={activeConversation.conversationId}
            loggedUser={loggedUser}
            friends={activeConversation.friends}
            onClose={handleCloseChat}
            position={-index}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ChatsScreen;