import { Button, ButtonProps, SxProps } from '@mui/material';
import React from 'react';

export type AdminButtonProps = ButtonProps & {
  id?: string;
  variant?: string;
  title: string;
  disableElevation?: boolean;
  sxProps?: SxProps;
};

const AdminButton: React.FC<AdminButtonProps> = ({
  id = 'button-contain',
  variant = 'contained',
  title,
  disableElevation = true,
  sxProps,
  ...props
}) => {
  return (
    <Button
      className='bodyText'
      id={id}
      data-testid={id}
      variant={variant}
      disableElevation={disableElevation}
      sx={{
        textTransform: 'capitalize',
        ...sxProps
      }}
      {...props}
    >
      {title}
    </Button>
  );
};

export default AdminButton;
