import { createAsyncThunk } from '@reduxjs/toolkit';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { addMessage, clearConnection, setConnectionId, updateActiveUsers, setConnection, updateMessageStatus, addGroupMessage } from '../store/signalrSlice';
import { signalrConnect, signalrNegotiate } from './api';
import { store } from 'store';

export const initializeSignalR = createAsyncThunk(
  'signalr/initialize',
  async (username: string, { dispatch }) => {
    try {

      const negotiateResponse = await signalrNegotiate(username);

      const { url, accessToken } = negotiateResponse;

      const connection = new HubConnectionBuilder()
        .withUrl(url, { accessTokenFactory: () => accessToken })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

      connection.on('newMessage', (message) => {
        dispatch(addMessage(message));
      });

      connection.on('updateUserStatus', (user: { userid: string; isonline: boolean }) => {
        dispatch(updateActiveUsers(user));
      });

      connection.on('messageStatus', (message) => {
        dispatch(updateMessageStatus(message));
      });

      connection.on('newGroupMessage', (message) => {
        dispatch(addGroupMessage(message));
      });
      
      await connection.start();

      dispatch(setConnectionId(connection.connectionId));
      dispatch(setConnection(connection));
      
      await signalrConnect({userId: username, connectionId: connection.connectionId ?? ""});

    } catch (error) {
      console.error('Failed to initialize SignalR:', error);
    }
  }
);

export const closeSignalRConnection = createAsyncThunk(
  'signalr/closeConnection',
  async (_, { dispatch }) => {
    try {
      const signalr = store.getState().signalr;

      if (signalr.connection) {
        await signalr.connection.stop();
      }
      dispatch(clearConnection());
    } catch (error) {
      console.error('Failed to close SignalR connection:', error);
    }
  }
);
