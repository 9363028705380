import { ApiPaths, ApiPaths_Groups } from 'models/apiPaths';
import { BASE_API, BASE_API_POST } from './axiosManagerFunctions';

export const getUrl = (mediaId: string) => {
  return `${BASE_API}/${ApiPaths.USER_IMAGE}/${mediaId}`;
};

export const userImgUrl = (imgId: string) => {
  return `${BASE_API_POST + '/' + ApiPaths.POST_MEDIA + '/' + imgId}`;
};

export const groupImgUrl = (imgId: string) => {
  return `${
    BASE_API_POST + '/' + ApiPaths_Groups.GROUP_IMAGE_MEDIA + '/' + imgId
  }`;
};

export const GroupBannerImgUrl = (imgId: string) => {
  return `${
    BASE_API_POST + '/' + ApiPaths_Groups.Group_BANNER_MEDIA + '/' + imgId
  }`;
};

export const userBannerUrl = (userBannerId: string) => {
  const url = `${
    BASE_API + '/' + ApiPaths.USER_BANNER_MEDIA + '/' + userBannerId
  }?time=${new Date().getTime()}`;
  return url;
};

export const userProfileUrl = (userImgId: string) => {
  const url = `${
    BASE_API + '/' + ApiPaths.USER_MEDIA + '/' + userImgId
  }?time=${new Date().getTime()}`;
  return url;
};